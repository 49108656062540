import React from 'react'
import { Redirect } from 'react-router-dom'
import { authenticationService } from 'services/authentication.service';

class ProtectedRoute extends React.Component {

    render() {
        const Component = this.props.component;
        const currentUser = authenticationService.currentUserValue;
        const isAuthenticated = currentUser !== undefined && currentUser !== null ;
        return isAuthenticated ? (
            <Component />
        ) : (
            <Redirect to={{ pathname: '/login' }} />
        );
    }
}

export default ProtectedRoute;