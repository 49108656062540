import React from "react";
import { Link } from "react-router-dom";
import { userService } from 'services/user.service';
import { authenticationService } from 'services/authentication.service';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Breadcrumb, BreadcrumbItem
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import DataTable from 'react-data-table-component';

const conditionalRowStyles = [
  {
    when: row => row.isActive == "Inactive account",
    style: row => ({
      backgroundColor: row.isActive ? '#efc34c' : 'inerit',
      color: row.isActive ? 'black' : 'inerit',
    }),
  },
];
const columns = [
    {
        name: 'Id',
        selector: 'id',
        sortable: true,
        right: false,
   },
   {
    name: 'UserName',
    selector: 'username',
    sortable: true,
    right: false,
  },
  {
    name: 'FullName',
    selector: 'fullName',
    sortable: true,
    right: false,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    right: false,
  },
  {
    name: 'Phone',
    selector: 'phone',
    sortable: true,
    right: false,
  },
  {
    name: 'State',
    selector: 'isActive',
    sortable: true,
    right: false,
  },
  {
    name: 'CreatedDate',
    selector: 'createdDate',
    sortable: true,
    right: false,
    width: '160px',
  },
  {
    name: 'Product Version',
    selector: 'productIds',
    sortable: true,
    right: false,
  },
];
  
class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users : [],
      usersList : [],
      errorMessage: '',
      toggledClearRows: false,
      toggleFailModal: false,
      totalRows:0,
      loading:false,
      startDate:new Date().toISOString().slice(0,10),
      endDate:new Date().toISOString().slice(0,10),
      userState:'all',
      keyword:''
    }
    this.getUsersByParams = this.getUsersByParams.bind(this);
    this.loadInitData = this.loadInitData.bind(this);
  }
    componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) {
      this.props.history.push('/');
    }else{
      let permissions = [];
      let authorities = authenticationService.getUserAuthorities();
      for( let i = 0; i < authorities.length; i++ ) {
          for( let j = 0; j < authorities.length; j++ ) {
            var obj = authorities[i][j];
            permissions.push(obj.permission) ;
          }
      }
      if(permissions.indexOf("manage_user") <= -1){
        this.props.history.push('/');
      }

    }
    const lastSelectedUser = sessionStorage.getItem("lastSelectedUser");
    if(lastSelectedUser != null && lastSelectedUser != undefined){
        let lastLoadedUser =JSON.parse(lastSelectedUser)
        this.loadInitData(0,10,lastLoadedUser.keyword, lastLoadedUser.startDate,lastLoadedUser.endDate)
        sessionStorage.removeItem("lastSelectedUser")
    }else{
        this.getUsersByParams(0,10);
    }
  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
    if(state === "close"){
      authenticationService.logout();
      this.props.history.push("/login");
     }
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
   if(state === "close"){
    window.location.reload();
    }
  };
  handleChange=(e)=> {
    this.setState({ keyword: e.target.value });
  }
  handleStartDateChange=(e)=> {
    this.setState({ startDate:e.target.value });
  }
  handleEndDateChange=(e)=> {
    this.setState({ endDate:e.target.value });
  }
  filterAccount=(e)=>{
    this.setState({ userState:e.target.value });
  }
  loadInitData(page,size,keyword,startDate,endDate){
        const status    = this.state.userState ;
        this.setState({ loading:true,keyword:keyword,startDate:startDate,endDate:endDate});
        userService.searchByParams(keyword,startDate,endDate,status,page,size)
            .then(response => {
                let values = response.content;
                this.setState({ totalRows: response.totalElements });
                if(values != null && values.length>0){
                    for (let i in values) {
                        if (values[i].productIds == "1") {
                            values[i].productIds = "V3.5";
                        }
                        if (values[i].productIds == "2") {
                            values[i].productIds = "V4";
                        }
                        if (values[i].productIds == "1,2" || values[i].productIds == "2,1") {
                            values[i].productIds = "V3,5,V4";
                        }
                    }
                }
                this.setState({
                    users: values,
                    usersList: values,
                    loading:false
                });
            }, (error) => {
                this.setState({ loading: false });
                console.log("ERROR : "+JSON.stringify(error))
                if(error && error.status === 401){
                    this.toggleFailModal("toggleFailModal","Your session is expired please login again.");
                }else{
                    this.toggleFailModal("toggleFailModal","Internal error please try again later.");
                }
            });

    }
 getUsersByParams(page,size){
    const keyword   = this.state.keyword;
    const status    = this.state.userState ;
    const startDate = this.state.startDate ;
    const endDate   = this.state.endDate ;
    this.setState({ loading:true });
    userService.searchByParams(keyword,startDate,endDate,status,page,size)
        .then(response => {
          let values = response.content;
          this.setState({ totalRows: response.totalElements });
          if(values != null && values.length>0){
            for (let i in values) {
              if (values[i].productIds == "1") {
                values[i].productIds = "V3.5";
              }
              if (values[i].productIds == "2") {
                values[i].productIds = "V4";
              }
              if (values[i].productIds == "1,2" || values[i].productIds == "2,1") {
                values[i].productIds = "V3,5,V4";
              }
            }
          }
          this.setState({
            users: values,
            usersList: values,
            loading:false
          });
        }, (error) => {
          this.setState({ loading: false });
          console.log("ERROR : "+JSON.stringify(error))
          if(error && error.status === 401){
            this.toggleFailModal("toggleFailModal","Your session is expired please login again.");
          }else{
            this.toggleFailModal("toggleFailModal","Internal error please try again later.");
          }
        });

  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.getUsersByParams(0,10);
    }
  }
  selectRows = state => {
    this.setState({ selectedRows: state.selectedRows });
  }
  onSelectRow = row => {
      const lastSelectedUser = {keyword:row.username,startDate:this.state.startDate,endDate:this.state.endDate};
      sessionStorage.setItem("lastSelectedUser",JSON.stringify(lastSelectedUser))
      this.props.history.push("/users/"+row.id);
  }
  handlePerRowsChange = page =>{
    this.getUsersByParams(page-1,10);
  }
  render() {
    return (
      <>
        <AppNavbar />
                {/* on success model */}
                <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal}
          toggle={() => this.toggleModal("successModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Welcome
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message"> User deleted with success</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* on failure model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.toggleFailModal}
          toggle={() => this.toggleFailModal("toggleFailModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Error
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("toggleFailModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
          <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("toggleFailModal")}
            >
              Ok
            </Button>
          </div>
        </Modal>
        <main className="profile-page version-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 main-version-bg ">
          <div className="breadcrumbs-wrap">
                    <Breadcrumb className="breadcrumbs-cust">
                        <BreadcrumbItem   active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Users</BreadcrumbItem>
                        </Breadcrumb>
          </div> 
             </section>
          <section className="section main-version-sub-bg main-h">
            <Container className="container-users">
              <Card className="card-profile mt--300">
              <Row className="justify-content-center">
              <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="user-search-warp">
                      <Button onClick={()=>this.getUsersByParams(0,10)}  className="btn-icon btn-3 search-btn"  color="default" outline type="button" size="sm">
                          <span className="btn-inner--icon">
                            <i className="fas fa-search search-btn-icon" />
                          </span>
                          <span className="btn-inner--text"></span>
                        </Button>
                        <input value={this.state.keyword} onKeyDown={this._handleKeyDown} onChange={this.handleChange}   className="search-api-input" type="text" placeholder="Username"/>
                        <select onChange={this.filterAccount} className="select-account" value={this.state.userState} >
                          <option value="all">All account</option>
                          <option value="true">Active account</option>
                          <option value="false">Inactive account</option>
                        </select>
                        <label className="search-user-date-label">Start date
                          <input  onChange={this.handleStartDateChange} value={this.state.startDate}  className="search-date-input"  type="date"/>
                        </label>
                        <label className="search-user-date-label">End date
                          <input onChange={this.handleEndDateChange} value={this.state.endDate}  className="search-date-input"  type="date"/></label>

                    </div>
                    </Col>
                    </Row>
                  <Col lg="12">
                    <Row className="row-grid users-wrap">
                        <DataTable
                            title=""
                            columns={columns}
                            data={this.state.users}
                            pagination="true"
                            highlightOnHover="true"
                            pointerOnHover="true"
                            data-tag="allowRowEvents"
                            onRowClicked={this.onSelectRow}
                            dense
                            Clicked
                            striped="true"
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            selectableRowsHighlight="true"
                            conditionalRowStyles={conditionalRowStyles}
                            onChangePage={this.handlePerRowsChange}
                            progressPending={this.state.loading}
                        />
                    </Row>
                    <Row className="row-grid users-buttons">
                    <Col lg="12">
                     
      
                    </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Container>
           
          </section>
         
        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default Users;
