import React from "react";
import { Link } from "react-router-dom";
import { configService } from 'services/config.service';
import { userService } from 'services/user.service';
import { authenticationService } from 'services/authentication.service';
import { emailService} from 'services/email.service';
import Player from '../views/Player'
// reactstrap components
import {
  Badge,
  Button,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Alert,
  Modal,
  Collapse, 
  Nav, NavItem, NavLink,
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle,
  Breadcrumb, BreadcrumbItem,
  Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Collapsible from 'react-collapsible';
class Help extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playVideo:false,
      emailSubject:'',
      fullName:'',
      companyName:'',
      emailAddress:'',
      emailBody:'',
      jobTitle:'',
      phone:'',
      successEmailModal:false
    }
  }
  playVideo = () =>{
    this.toggleModal("playVideo")
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  onSuccessSendEmail = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  handleSubmit= (e) => {
    e.preventDefault();
    this.sendRequestDemo();

  }
  sendRequestDemo = () => {
    const {emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone} = this.state
    emailService.requestDemoEmail(emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone)
    .then(
        response => {
          this.onSuccessSendEmail("successEmailModal");
          this.setState({
            emailSubject:'',
            fullName:'',
            companyName:'',
            emailAddress:'',
            emailBody:'',
            jobTitle: '',
            phone: ''
            });
        },
        (error) => {
          let errorType = new String(error);
          if(error !== undefined && error !== null && !errorType.includes("TypeError")){
            console.log(JSON.stringify(JSON.parse(error)))
          }else{
            //this.toggleFailModal("failureModal","Internal error please try again later.");
          }

        }
     );
}
  checkForm = () => {
    let isValidSubject= false;
    let isValidJob= false;
    let isValidEmail= false;
    let isValidPhone= false;
    const {emailSubject, emailAddress,jobTitle,phone} = this.state
    if(!emailSubject){
      if(document.getElementById("subject-input"))
      document.getElementById("subject-input").classList.add("has-error");
      return false ;
    }else{
      let element1 =  document.getElementById("subject-input");
      if(element1) element1.classList.remove("has-error");
      isValidSubject = true
    }
    if(!emailAddress){
      if(document.getElementById("email-input"))
      document.getElementById("email-input").classList.add("has-error");
      return false ;
    }else{
      let element1 =  document.getElementById("email-input");
      if(element1) element1.classList.remove("has-error");
      isValidEmail = true
    }
    if(!jobTitle){
      if(document.getElementById("job-input"))
      document.getElementById("job-input").classList.add("has-error");
      return false ;
    }else{
      let element1 =  document.getElementById("job-input");
      if(element1) element1.classList.remove("has-error");
      isValidJob = true
    }
     if(!phone){
      if(document.getElementById("phone-input"))
      document.getElementById("phone-input").classList.add("has-error");
      return false ;
    }else{
      let element1 =  document.getElementById("phone-input");
      if(element1) element1.classList.remove("has-error");
      isValidPhone = true
    } 
    return isValidPhone && isValidEmail && isValidJob && isValidSubject ;
  }
  isFormValid = () => {
    let isValidSubject= false;
    let isValidJob= false;
    let isValidEmail= false;
    let isValidPhone= false;
    const {emailSubject, emailAddress,jobTitle,phone} = this.state
    if(!emailSubject){
      if(document.getElementById("subject-input"))
      return false ;
    }else{
      isValidSubject = true
    }
    if(!emailAddress){
      return false ;
    }else{
      isValidEmail = true
    }
    if(!jobTitle){
      return false ;
    }else{
      isValidJob = true
    }
     if(!phone){
      return false ;
    }else{
      isValidPhone = true
    } 
    return isValidPhone && isValidEmail && isValidJob && isValidSubject ;
  }
  render() {
    return (
      <>
            {/* play video model */}
              <Modal
                  className="modal-dialog-centered demo-video-modal"
                  isOpen={this.state.playVideo}
                  toggle={() => this.toggleModal("playVideo")}
                >
                  <div className="modal-header">
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("playVideo")}
                    >
                      <span aria-hidden={true}>X</span>
                    </button>
                  </div>
                  <div className="modal-body demo-video-modal-height">
                    <Alert className="alert-default react-player-warp">
                      {/*    <video width="480" height="240"  className="demo-video-container" controls autoplay controlsList="nodownload">
                        <source src={require("assets/video/ConnectAPI.mp4")} />
                        </video>  */}
                        <Player  className="demo-video-container"/>
                    </Alert>
                  </div>
                  <div className="modal-footer">
                  </div>
             </Modal>
        {/* on success model */}
         <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successEmailModal}
          toggle={() => this.onSuccessSendEmail("successEmailModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Thank you
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.onSuccessSendEmail("successEmailModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message">We will contact you shortly to book a convenient time.</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.onSuccessSendEmail("successEmailModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
        <AppNavbar />

        <main className="profile-page demo-page" ref="main">
          <section className="section-shaped my-0  "> 
          <div className="breadcrumbs-wrap">
                    <Breadcrumb className="breadcrumbs-cust">
                        <BreadcrumbItem   active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Guide</BreadcrumbItem>
                        </Breadcrumb>
          </div> 
          </section>
          <section className="section demo-section">
       
          <Container>
          <Row className="justify-content-center">
            </Row>
              <Row className="justify-content-center">
                <Col lg="10">
                  <Card className="bg-gradient-secondary shadow">
                   <Form role="form" onSubmit={this.handleSubmit}>
                    <CardBody className="p-lg-12">
                    <Row>
                    <Col lg="4" className="demo-video-no-margin">
                      <h3 className="demo-title">Request a Demo</h3>
                      <img
                      alt="..."
                      className="demo-video-img"
                      src={require("assets/img/video_portal_demo.png")}
                     />
                      <img
                      onClick={this.playVideo}
                      alt="..."
                      className="demo-video-img demo-video-img-mask"
                      src={require("assets/img/video_portal_demo_mask.png")}
                     />
                     </Col>
                     <Col lg="6" className="demo-video-no-margin">
                      <p className="mt-0 demo-description">
                      Experience a live customized demo, get answers to your specific questions, and find out why PowerCARD Connect'API is the right choice for your organization
                      </p>
                      <p className="mt-0 demo-description">
                      Request your PowerCARD Connect'API demo now and we will contact you shortly to book a convenient time.
                      </p>
                      </Col>
                      </Row>
                      <div className="demo-request-separator">
                      <h5 className="demo-title">Please enter your information<span className="demo-form-required"> *</span></h5>
                      </div>
                      <Row>
                      <Col lg="6">
                      <FormGroup
                      >
                        <label htmlFor="subject">Subject</label><span className="demo-form-required"> *</span>
                        <InputGroup id="subject-input" className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            id="subject"
                            type="text"
                            name="emailSubject"
                            value={this.state.emailSubject}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      <Col lg="6">
                        {/** Full name */}
                        <FormGroup
                      >
                        <label htmlFor="fullname">Full Name</label>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-badge" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="fullname"
                            placeholder=""
                            type="text"
                            name="fullName"
                            value={this.state.fullName}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                            {/** Company */}
                      <label htmlFor="company">Company</label>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-building" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="company"
                            placeholder=""
                            type="text"
                            name="companyName"
                            value={this.state.companyName}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                        </Col>
                        <Col lg="6">
                           {/** Job title */}
                      <FormGroup>
                      <label htmlFor="jobtitle">Job Title</label><span className="demo-form-required"> *</span>
                        <InputGroup id="job-input" className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-building" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            type="text"
                            id="jobtitle"
                            name="jobTitle"
                            value={this.state.jobTitle}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                        </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                      <Col lg="6">
                         {/** Phone number */}
                      <FormGroup>
                      <label htmlFor="phoneNumber">Phone Number</label><span className="demo-form-required"> *</span>
                        <InputGroup id="phone-input" className="input-group-alternative">
                         
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-mobile-button" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            id="phoneNumber"
                            placeholder=""
                            type="text"
                            name="phone"
                            pattern="[0-9]*"
                            value={this.state.phone}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                        </Col>
                        <Col lg="6">
                          
                       {/** Email */}
                      <FormGroup
                      >
                        <label htmlFor="businessEmail">Business Email</label><span className="demo-form-required"> *</span>
                        <InputGroup id="email-input" className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=""
                            type="email"
                            id="businessEmail"
                            name="emailAddress"
                            value={this.state.emailAddress}
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                        </Col>
                      </Row>
                     
                     
                      <FormGroup className="mb-4">
                      <label htmlFor="additonalInformation">Additional information</label>
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="emailBody"
                          id="additonalInformation"
                          placeholder="Additional information related to your business needs"
                          rows="4"
                          type="textarea"
                          onChange={(event) => this.handleUserInput(event)}
                          value={this.state.emailBody}
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round btn-contact-us"
                          color="default"
                          size="lg"
                          type="submit"
                          disabled={!this.isFormValid()}
                
                        >
                          Submit
                        </Button>
                      </div>
                      <div className="privacy-policy">
                      By submitting the form, I agree to the <a className="privacy-policy-link" target="_blank" href="https://www.hps-worldwide.com/privacy-policy">Privacy Policy.</a>
                      </div>
                    </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
         
        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default Help;
