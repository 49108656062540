import React from "react";
import { Link } from "react-router-dom";
import { emailService} from 'services/email.service';
import Player from '../views/Player'
// reactstrap components
import {
    Badge,
    Button,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Modal,
    Collapse,
    Nav, NavItem, NavLink,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Breadcrumb, BreadcrumbItem,
    Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Collapsible from 'react-collapsible';
class PressRelease extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <AppNavbar />
                <main className="profile-page demo-page" ref="main">
                    <section className="section-shaped my-0  ">
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb className="breadcrumbs-cust">
                                <BreadcrumbItem   active>Home</BreadcrumbItem>
                            </Breadcrumb>
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                                <BreadcrumbItem active>Guide</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </section>
                    <section className="section demo-section">

                        <Container>
                            <Row className="justify-content-center">
                                <h5>2020 - 2022</h5>
                                <p className="about-txt1">HPS has grown rapidly to become one of the leading global cards and payments technology companies, with over 450 clients in 90 countries, providing them with cutting edge payments technology.</p>
                                <div  style={{
                                    width: '100%'
                                }}>
                                    <Card
                                        body
                                        className="my-2 press-release-card"
                                    >
                                        <CardTitle tag="h5">
                                            HPS s'associe à Visa pour accélérer l'accès au réseau du système paiements
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            29 Septembre 2022
                                        </CardSubtitle>
                                        <CardText>
                                            Casablanca, 29 Septembre 2022 - HPS, leader dans la fourniture de solutions et services de paiement, annonce aujourd'hui l’obtention de la licence d'adhésion principale de Visa, leader mondial des paiements numériques, qui permettra aux clients de HPS de rejoindre le réseau mondial de paiements Visa
                                        </CardText>
                                        <Button color="primary" className="btn-press-release-more">
                                           More information >
                                        </Button>
                                    </Card>
                                    <Card
                                        body
                                        className="my-2 press-release-card"
                                    >
                                        <CardTitle tag="h5">
                                            HPS partners with Visa to fast-track access to the scheme’s payments network
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            September 29th, 2022
                                        </CardSubtitle>
                                        <CardText>
                                            Casablanca, 29 September 2022 - HPS, the leading global provider of payment solutions and services, today announces it has obtained a principal membership license from Visa, the world leader in digital payments, which will enable HPS’ clients to join the global Visa payments network
                                        </CardText>
                                        <Button color="primary" className="btn-press-release-more">
                                            More information >
                                        </Button>
                                    </Card>
                                    <Card
                                        body
                                        className="my-2 press-release-card"
                                    >
                                        <CardTitle tag="h5">
                                            HPS transfère son bureau sud-africain au Cap
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            26 Juillet 2022
                                        </CardSubtitle>
                                        <CardText>
                                            Casablanca & Cape Town, 22 July 2022 – HPS, the leading global provider of payment solutions, today announced its plans to move its South Africa office to Cape Town as it looks to expand its team and support new business growth.
                                        </CardText>
                                        <Button color="primary" className="btn-press-release-more">
                                            More information >
                                        </Button>
                                    </Card>
                                    <Card
                                        body
                                        className="my-2 press-release-card"
                                    >
                                        <CardTitle tag="h5">
                                            HPS relocates South Africa office to Cape Town
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            July 26th, 2022
                                        </CardSubtitle>
                                        <CardText>
                                            Casablanca & Cape Town, 22 July 2022 – HPS, the leading global provider of payment solutions, today announced its plans to move its South Africa office to Cape Town as it looks to expand its team and support new business growth.
                                        </CardText>
                                        <Button color="primary" className="btn-press-release-more">
                                            More information >
                                        </Button>
                                    </Card>
                                </div>
                            </Row>
                        </Container>
                    </section>

                </main>
                <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
                <SimpleFooter />
            </>
        );
    }
}

export default PressRelease;
