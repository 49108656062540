import React from "react";
import SwaggerUI from "swagger-ui-react"
import { Link } from "react-router-dom";

import { authenticationService } from 'services/authentication.service';
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
// reactstrap components
import {
    Row,
    Breadcrumb,
    BreadcrumbItem,
    ListGroup,
    ListGroupItem,
    Badge,
    Button,
    Collapse,
    Card,
    CardBody,
    FormGroup,
    Label,
    Col,
    Input, CardFooter, Spinner
} from "reactstrap";
import Tree from "react-animated-tree-v2";
import {configService} from "../services/config.service";
import {auditService} from "../services/audit.service";

const treeStyles = {
    position: 'absolute',
    top: 160,
    left: 10,
    color: '#5F56BB',
    fill: '#5F56BB',
    width: '20%',
    height: '100%'

}

class Swagger2 extends React.Component {


  constructor(props) {
    super(props);
      this.state = {
          moduleData:[],
          apiVersionList:[],
          subApiList:[],
          yamlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/tokenAuthentication.yaml',
          htmlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/statics/index.html',
          isOpen:false,
          selectedLanguageCode:"java"
      }
      this.onComplete = this.onComplete.bind(this);
  }
    traceOnCallApi(api){
        auditService.trace("Call API", "Page Swagger", new Date(),authenticationService.getCurrentUser(),api)
            .then(r  => {})
    }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
      this.loadModulesData();

  }
    onComplete(){
        if(document.getElementsByClassName("authorize")[0]){
            document.getElementsByClassName("authorize")[0].innerHTML = '<span>Setting an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';
        }
        else{
            return
        }
        let token = sessionStorage.getItem("connectApiJwt")
        if(token){
            document.getElementsByClassName("authorize")[0].innerHTML = '<span>Reset an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';

        }
        const url = new URL(window.location.href);
        const apiName = url.searchParams.get('api');
        if(apiName !== null){
            this.doSearch(apiName,"##9e191d")
        }
        const el = document.getElementById("testCase1");
        // -- end
        document.addEventListener('click', function(e) {
            e = e || window.event;
            var target = e.target || e.srcElement,
                text = target.textContent || target.innerText;
            if(text && text=="Authorize"){
                let element =  document.querySelector(".swagger-ui .auth-container input[type=text]")
                if(element && element.value != null && element.value !="" && element.value!=undefined){
                    sessionStorage.setItem("connectApiJwt",element.value)
                    if( document.getElementsByClassName("authorize")[0])
                    {
                        document.getElementsByClassName("authorize")[0].innerHTML = '<span>Reset an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';
                    }
                }
            }
            // load multiple test cases
            const elementClicked = document.getElementById("testCase1");
            if(elementClicked && elementClicked.getAttribute('listener') !== 'true'){
                elementClicked.addEventListener('change', (event) => {
                    elementClicked.setAttribute('listener', 'true');
                    let code = document.querySelector(".body-param__text");
                    if(code){
                        let json = ""
                        if(event.target && event.target.value=="bank"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkJBTksiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="account_type_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUNDT1VOVF9UWVBFX0xJU1QiLAogICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="action_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUNUSU9OX0xJU1QiLAogICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="action_table"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkFDVElPTl9UQUJMRSIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="address_type_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUREUkVTU0VTX1RZUEVfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQoK"))), null, 2));
                        }
                        else if(event.target && event.target.value=="branch"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkJSQU5DSCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_batch_status"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfQkFUQ0hfU1RBVFVTIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cgo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_product"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfUFJPRFVDVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_status"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfU1RBVFVTIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_reason"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBU0VfUkVBU09OIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_type"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBU0VfVFlQRSIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="city"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNJVFkiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="country"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNPVU5UUlkiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="cr_profile"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNSX1BST0ZJTEUiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="currency_table"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNVUlJFTkNZX1RBQkxFIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="document_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkRPQ1VNRU5UX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="language_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkxBTkdVQUdFX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="mcc"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIk1DQyIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="chain"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNIQUlOIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="owner_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIk9XTkVSX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="plastic_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBMQVNUSUNfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="processing_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBST0NFU1NJTkdfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="protocol_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBST1RPQ09MX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="region"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlJFR0lPTiIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="status_reason_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlNUQVRVU19SRUFTT05fTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="title_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRJVExFX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="transaction_status"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRSQU5TQUNUSU9OX1NUQVRVUyIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="transaction_definition"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRSQU5TQUNUSU9OU19ERUZJTklUSU9OIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="vip_list"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlZJUF9MSVNUIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="withdrawl_reason_code"){
                            json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIldJVEhEUkFXQUxfUkVBU09OX0NPREUiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0KCg=="))), null, 2));
                        }
                        let requestContainer =  document.querySelector(".body-param");
                        if(requestContainer){
                            var input = document.querySelector('.body-param__text');
                            var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
                            nativeInputValueSetter.call(input, json);
                            var ev2 = new Event('input', { bubbles: true});
                            input.dispatchEvent(ev2);
                        }
                    }
                },true);
            }
        }, false);
    }
  loadModulesData(){
      let requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' }};
      let url = process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/module-descriptor.json';
      fetch(url, requestOptions)
          .then((r) => r.json())
          .then(data => {
              if(data.modules[0]!==undefined && data.modules[0]!==null &&
                  data.modules[0].apis[0]!==undefined && data.modules[0].apis[0]!==null ){
                  this.setState({
                      subApiList: data.modules[0].apis[0].subApiList,
                      yamlFile : process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/'+data.modules[0].apis[0].file,
                  })
              }
              this.setState({
                  moduleData: data.modules
              })
          })
  }
  loadApiVersion(apiYaml,staticContent){
      this.setState({
          yamlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/'+apiYaml,
      })
      if(staticContent != undefined && staticContent != null && staticContent == true){
          document.getElementById( 'show-swagger-ui' ).style.display = 'block';
          document.getElementById( 'show-static-content' ).style.display = 'none';
      }else{
          document.getElementById( 'show-swagger-ui' ).style.display = 'block';
      }

  }
    toggle(){
        this.setState({
         isOpen:!this.state.isOpen
        });
    }

  loadApiData(apiYaml,isStaticContent,apiVersion,subApis,webhookLink){
      if(webhookLink ===true){
          window.location.href = "/webhooks-ui/"+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module;
      }
      this.setState({
          yamlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/'+apiYaml,
          htmlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/statics/'+apiYaml
      })
      if(apiVersion != undefined && apiVersion!= null && apiVersion.length >0){
          this.setState({
                  apiVersionList: apiVersion
              }
          )
      }else{
          this.setState({
                  apiVersionList: []
              }
          )
      }
      if(subApis != undefined && subApis!= null && subApis.length >0){
          this.setState({
              subApiList: subApis
              }
          )
      }else{
          this.setState({
              subApiList: []
              }
          )
      }
      if(isStaticContent != null && isStaticContent != undefined && isStaticContent==true ){
          document.getElementById( 'show-swagger-ui' ).style.display = 'none';
          document.getElementById( 'show-static-content' ).style.display = 'block';
      }else{
          document.getElementById( 'show-swagger-ui' ).style.display = 'block';
          document.getElementById( 'show-static-content' ).style.display = 'none';
      }

  }
    generateSdk = () => {
        document.getElementById('sdk-progress').style.display = 'block';
        let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
        if(REACT_BASE_URL == undefined || REACT_BASE_URL == null || REACT_BASE_URL==""){
            REACT_BASE_URL="http://localhost:3000"
        }
        configService.generateSdk(this.state.selectedLanguageCode,REACT_BASE_URL+this.state.yamlFile)
            .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'client_sdk_'+this.state.selectedLanguageCode+"_"+new Date().getTime()); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                document.getElementById('sdk-progress').style.display = 'none';
            }, (error) => {
                document.getElementById('sdk-progress').style.display = 'none';
             console.log("error while generating sdk :"+error)
            });
    }
    handleSelectLanguageChange = event => {
        this.setState({
            selectedLanguageCode: event.target.value
            }
        )
    };
  render() {
    return (
      <>
        <AppNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg section-bg">
          <Row>
              <div className="breadcrumbs-swagger-wrap">
                    <Breadcrumb className="breadcrumbs-cust breadcrumbs-swagger">
                        <BreadcrumbItem  active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-version"))}>Releases</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-solution"))}>Solutions</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-module"))}>Modules</Link></BreadcrumbItem>
                          <BreadcrumbItem active>OpenAPI 3.1.0</BreadcrumbItem>
                        </Breadcrumb>
              </div>
              <hr className="solid"/>
              <hr className="solid-bottom"/>
              <div className="separator separator-bottom separator-skew">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                  >
                      <polygon
                          className="fill-white"
                          points="2560 0 2560 100 0 100"
                      />
                  </svg>
              </div>
              </Row>

              <div className="api-explorer-container">
                  <Row className="api-explorer">
                      <Tree content="API Overview"  open style={treeStyles}>
                          {
                              this.state.moduleData.map(e=> (
                                  <Tree   style={{   fontWeight: 'bold' }}  content={e.moduleName} open={e.expanded}>
                                      {
                                          e.apis.map(i=>(
                                              <Tree  style={{   fontWeight: 'normal' }}   itemId={i.file} content={i.name}     onItemClick={(itemId) => this.loadApiData(itemId,i.staticContent,i.versioning,i.subApiList,i.webhookLink)}
                                              />
                                          ))
                                      }
                                  </Tree>

                              ))
                          }
                      </Tree>
                  </Row>
                  <Row  className="api-explorer-details">
                      <div id="show-static-content" >
                          <iframe  src={this.state.htmlFile}></iframe>
                      </div>
                      <div id="show-swagger-ui" >
                          <SwaggerUI persistAuthorization="true" url={this.state.yamlFile} displayRequestDuration  docExpansion ="full" onComplete={() => this.onComplete()} requestInterceptor={req =>  {
                              if(req!=null && req.url!= null && !req.url.includes(".yaml")){
                                  this.traceOnCallApi(req.url)
                              }
                              const bearerToken = sessionStorage.getItem('connectApiJwt');
                              if (!bearerToken) {
                                  console.log("No token found")
                                  return req;
                              } else {
                                  req.headers.Authorization = `Bearer ${bearerToken}`;
                                  return req;
                              }
                          }
                          }/>
                      </div>
                  </Row>
                  <Row className="api-explorer-select">
                      {this.state.apiVersionList.length > 0 &&
                      <div className="api-explorer-sub-select">
                          <h4>API Versions</h4>
                          <div>
                              <ListGroup className="api-version-wrapper">
                                  {
                                      this.state.apiVersionList.map(e=> (
                                          <ListGroupItem className="api-version" onClick={()=>this.loadApiVersion(e.file,e.staticContent)}>
                                              {
                                                  e.HttpVerb=='PATCH' &&
                                                  <Badge className="api-version-verb badge-patch" color="primary">
                                                      PATCH
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='DELETE' &&
                                                  <Badge className="api-version-verb badge-delete" color="danger">
                                                      DELETE
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='PUT' &&
                                                  <Badge className="api-version-verb badge-put" color="danger">
                                                      PUT
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='GET' &&
                                                  <Badge className="api-version-verb badge-get" color="danger">
                                                      GET
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='POST' &&
                                                  <Badge className="api-version-verb badge-post" color="primary">
                                                      POST
                                                  </Badge>
                                              }
                                              <span>  {e.version}</span>
                                              <span className="api-version-latest">  {e.deprecated == true ? '':'   - Latest'}</span>
                                          </ListGroupItem>
                                      ))
                                  }
                              </ListGroup>
                          </div>
                          <div className="client-sdk-title">
                              <Button size="sm" className="btn-display-sdk" onClick={()=>this.toggle()} style={{ marginBottom: '1rem' }}>
                                  <i className="fas fa fa-download"></i> Client SDKs
                              </Button>
                              <Collapse isOpen={this.state.isOpen}>
                                  <Card className="client-sdk-card">
                                      <CardBody className="sdk-card-body">
                                          <FormGroup row>
                                              <Col sm={12}>
                                                  <h6>Language</h6>
                                                  <Input
                                                      value={this.state.selectedLanguageCode}
                                                      onChange={(event)=>this.handleSelectLanguageChange(event)}
                                                      bsSize="sm"
                                                      id="exampleSelect"
                                                      name="select"
                                                      type="select"
                                                      className="sdk-select-lang"
                                                  >
                                                      <option value="csharp">
                                                          csharp
                                                      </option>
                                                      <option value="csharp-dotnet2">
                                                          csharp-dotnet2
                                                      </option>
                                                      <option value="dart">
                                                          dart
                                                      </option>
                                                      <option value="dynamic-html">
                                                          dynamic-html
                                                      </option>
                                                      <option value="go">
                                                          go
                                                      </option>
                                                      <option value="html">
                                                          html
                                                      </option>
                                                      <option value="html2">
                                                          html2
                                                      </option>
                                                      <option value="java">
                                                          java
                                                      </option>
                                                      <option value="javascript">
                                                          javascript
                                                      </option>
                                                      <option value="jaxrs-cxf-client">
                                                          jaxrs-cxf-client
                                                      </option>
                                                      <option value="kotlin-client">
                                                          kotlin-client
                                                      </option>
                                                      <option value="openapi">
                                                          openapi
                                                      </option>
                                                      <option value="openapi-yaml">
                                                          openapi-yaml
                                                      </option>
                                                      <option value="php">
                                                          php
                                                      </option>
                                                      <option value="python">
                                                          python
                                                      </option>
                                                      <option value="r">
                                                          r
                                                      </option>
                                                      <option value="ruby">
                                                          ruby
                                                      </option>
                                                      <option value="scala">
                                                          scala
                                                      </option>
                                                      <option value="swift3">
                                                          swift3
                                                      </option>
                                                      <option value="swift4">
                                                          swift4
                                                      </option>
                                                      <option value="swift5">
                                                          swift5
                                                      </option>
                                                      <option value="typescript-angular">
                                                          typescript-angular
                                                      </option>
                                                      <option value="typescript-angular">
                                                          typescript-angular
                                                      </option>
                                                      <option value="typescript-fetch">
                                                          typescript-fetch
                                                      </option>
                                                  </Input>
                                                  <Spinner animation="border" className="sdk-progress" id="sdk-progress"/>
                                                  <Button onClick={()=>this.generateSdk()} className="sdk-button-generate" size="sm" >Generate</Button>
                                              </Col>
                                          </FormGroup>
                                      </CardBody>
                                      <CardFooter className="sdk-generator">
                                          OPENAPI Generator V6.5.0
                                      </CardFooter>
                                  </Card>
                              </Collapse>
                          </div>
                      </div>}
                      {this.state.subApiList.length > 0 &&
                          <div className="api-explorer-sub-select">
                              <h4>API Reference</h4>
                              <div>
                                  <ListGroup className="api-version-wrapper">
                                      {
                                          this.state.subApiList.map(e=> (
                                              <ListGroupItem className="api-version" onClick={()=>this.loadApiVersion(e.file,e.staticContent)}>
                                                  {
                                                      e.HttpVerb=='PATCH' &&
                                                      <Badge className="api-version-verb badge-patch" color="primary">
                                                         PATCH
                                                      </Badge>
                                                  }
                                                  {
                                                      e.HttpVerb=='DELETE' &&
                                                      <Badge className="api-version-verb badge-delete" color="danger">
                                                          DELETE
                                                      </Badge>
                                                  }
                                                  {
                                                      e.HttpVerb=='PUT' &&
                                                      <Badge className="api-version-verb badge-put" color="danger">
                                                          PUT
                                                      </Badge>
                                                  }
                                                  {
                                                      e.HttpVerb=='GET' &&
                                                      <Badge className="api-version-verb badge-get" color="danger">
                                                          GET
                                                      </Badge>
                                                  }
                                                  {
                                                      e.HttpVerb=='POST' &&
                                                      <Badge className="api-version-verb badge-post" color="primary">
                                                          POST
                                                      </Badge>
                                                  }
                                                  <span>  {e.name} </span>
                                                  <span className="api-version-deprecated">  {e.deprecated == true ? ' (Deprecated)':''}</span>
                                              </ListGroupItem>
                                          ))
                                      }
                                  </ListGroup>
                              </div>
                              <div className="client-sdk-title">
                                  <Button size="sm" className="btn-display-sdk" onClick={()=>this.toggle()} style={{ marginBottom: '1rem' }}>
                                      <i className="fas fa fa-download"></i> Client SDKs
                                  </Button>
                                  <Collapse isOpen={this.state.isOpen}>
                                      <Card className="client-sdk-card">
                                          <CardBody className="sdk-card-body">
                                              <FormGroup row>
                                                  <Col sm={12}>
                                                      <h6 className="sdk-kit">Software Development Kits (SDKs)</h6>
                                                      <Input
                                                          value={this.state.selectedLanguageCode}
                                                          onChange={(event)=>this.handleSelectLanguageChange(event)}
                                                          bsSize="sm"
                                                          id="exampleSelect"
                                                          name="select"
                                                          type="select"
                                                          className="sdk-select-lang"
                                                      >
                                                          <option value="csharp">
                                                              csharp
                                                          </option>
                                                          <option value="csharp-dotnet2">
                                                              csharp-dotnet2
                                                          </option>
                                                          <option value="dart">
                                                              dart
                                                          </option>
                                                          <option value="dynamic-html">
                                                              dynamic-html
                                                          </option>
                                                          <option value="go">
                                                              go
                                                          </option>
                                                          <option value="html">
                                                              html
                                                          </option>
                                                          <option value="html2">
                                                              html2
                                                          </option>
                                                          <option value="java">
                                                              java
                                                          </option>
                                                          <option value="javascript">
                                                              javascript
                                                          </option>
                                                          <option value="jaxrs-cxf-client">
                                                              jaxrs-cxf-client
                                                          </option>
                                                          <option value="kotlin-client">
                                                              kotlin-client
                                                          </option>
                                                          <option value="openapi">
                                                              openapi
                                                          </option>
                                                          <option value="openapi-yaml">
                                                              openapi-yaml
                                                          </option>
                                                          <option value="php">
                                                              php
                                                          </option>
                                                          <option value="python">
                                                              python
                                                          </option>
                                                          <option value="r">
                                                              r
                                                          </option>
                                                          <option value="ruby">
                                                              ruby
                                                          </option>
                                                          <option value="scala">
                                                              scala
                                                          </option>
                                                          <option value="swift3">
                                                              swift3
                                                          </option>
                                                          <option value="swift4">
                                                              swift4
                                                          </option>
                                                          <option value="swift5">
                                                              swift5
                                                          </option>
                                                          <option value="typescript-angular">
                                                              typescript-angular
                                                          </option>
                                                          <option value="typescript-angular">
                                                              typescript-angular
                                                          </option>
                                                          <option value="typescript-fetch">
                                                              typescript-fetch
                                                          </option>
                                                      </Input>
                                                      <Spinner animation="border" className="sdk-progress" id="sdk-progress"/>
                                                      <Button onClick={()=>this.generateSdk()} className="sdk-button-generate" size="sm" >Generate</Button>
                                                  </Col>
                                              </FormGroup>
                                          </CardBody>
                                          <CardFooter className="sdk-generator">
                                              OPENAPI Generator V6.5.0
                                          </CardFooter>
                                      </Card>
                                  </Collapse>
                              </div>
                          </div>}
                  </Row>
              </div>

          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Swagger2;
