/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className=" text-primary font-weight-light mb-2 footer-first-txt">
                  Thank you for choosing us!
                </h3>
                <h5 className=" mb-0 font-weight-light footer-second-txt">
                  Let's get in touch on any of these platforms.
                </h5>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  href="https://twitter.com/hpsmaroc"
                  id="tooltip475038074"
                  target="_blank"
                >
                    <i className="fa fa-twitter" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  href="https://www.linkedin.com/company/hps"
                  id="tooltip837440413"
                  target="_blank"
                >
                    <i className="fa fa-linkedin" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440413">
                  Follow us
                </UncontrolledTooltip>
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  href="https://www.youtube.com/channel/UCG8SC8iSUZlsqR9oOyvG7nA"
                  id="tooltip837440414"
                  target="_blank"
                >
                    <i className="fa fa-youtube" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Follow us
                </UncontrolledTooltip>
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                  className="copyright-link"
                    href="https://www.hps-worldwide.com/about-hps"
                    target="_blank"
                  >
                      Copyright HPS. All rights reserved.                
                  </a>
                  .
                </div>
              </Col>
              <Col md="6">
                
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
