import React from "react";
import { Link } from "react-router-dom";
import { configService } from 'services/config.service';
import { userService } from 'services/user.service';
import { authenticationService } from 'services/authentication.service';
import Select from 'react-select'
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { browserHistory } from 'react-router';

class UsersDetail extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      options : [
            { value: '1', label: 'PowerCard 3.5' },
            { value: '2', label: 'PowerCard V4' },
            { value: '3', label: 'V4' }

      ],
      users : [],
      errorMessage: '',
      username:'',
      email:'',
      phone: '',
      productIds:'',
      enabled: false,
      enableValidityDate: false,
      confirmeDeleteModal: false,
      fullName: '',
      roles: [],
      userProducts:[],
      selectedProduct:"",
      disableUpdateUser: false,
      startDate:new Date().toISOString().slice(0,10),
      endDate:new Date().toISOString().slice(0,10),
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) {
      this.props.history.push('/');
    }else{
      let permissions = [];
      let authorities = authenticationService.getUserAuthorities();
      for( let i = 0; i < authorities.length; i++ ) {
          for( let j = 0; j < authorities.length; j++ ) {
            var obj = authorities[i][j];
            permissions.push(obj.permission) ;
          }
      }
      if(permissions.indexOf("manage_user") <= -1){
       this.props.history.push('/');
      }
    }
    this.getUserById(this.props.match.params.id);
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  onUpdateUser = () => {
        let {username, email,phone, password,fullName,productIds,startDate,endDate} = this.state;
        let validityRangeDate = "";
        if(this.state.enableValidityDate != true){
            startDate = "";
            endDate = "" ;
            validityRangeDate="";
        }else{
            validityRangeDate = startDate+"|"+endDate;
        }
        userService.updateUser(this.props.match.params.id,username,email,phone,password,fullName,productIds,validityRangeDate)
        .then(
            user => {
              this.toggleModal("successModal");
            },
            (error) => {
              let errorType = new String(error);
              if(error !== undefined && error !== null && !errorType.includes("TypeError")){
                console.log(JSON.stringify(JSON.parse(error)))
                let message = JSON.parse(error);
                this.toggleFailModal("failureModal",message.errorMessage);
              }else{
                this.toggleFailModal("failureModal","Internal error please try again later.");
              }

            }
         );
  }
  isFormValid = () => {
    const {username, email,phone,fullName} = this.state
    return username && email && fullName;
  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
    if(state === "close"){
      authenticationService.logout();
      this.props.history.push("/login");
     }
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
   if(state === "close"){
    this.props.history.push("/users");
    }
  };
    updateProductsIds(selectedOptions) {
        let ids = selectedOptions.map(option => option.value);
        this.setState({
            productIds: ids.join(','),
            selectedProduct: selectedOptions
        });
    }

    handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  toggleConfirmDeleteModal = (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      message: msg
    });
    if(state === "yes"){
        userService.deleteUserByIds(this.props.match.params.id)
        .then(response => {
          this.props.history.push("/users");
        }, (error) => {
            console.log("ERROR : "+JSON.stringify(error))
            if(error && error.status === 401){
              this.toggleFailModal("failureModal","Your session is expired please login again.");
            }else{
              this.toggleFailModal("failureModal","Internal error please try again later.");
            }
        });
      }
  }
  // Delete user by id
  onDeleteUser(){
    this.toggleConfirmDeleteModal("confirmeDeleteModal","Are you sure you want to permanently delete this user ?")
  }
  // Previews page
  previewsPage(){
    this.props.history.push("/users");
  }
  // get user by id
    getUserById(uuid) {
        userService.getUserById(uuid)
            .then(response => {
                this.setState({
                    username: response.username,
                    email: response.email,
                    phone: response.phone,
                    enabled: response.enabled,
                    fullName: response.fullName,
                    productIds: response.productIds,
                    roles: response.roles,
                    validityRangeDate: response.validityRangeDate
                });

                if (this.state.validityRangeDate != null) {
                    let startDate = this.state.validityRangeDate.substring(0, this.state.validityRangeDate.indexOf("|"));
                    let endDate = this.state.validityRangeDate.substring(this.state.validityRangeDate.indexOf("|") + 1);
                    this.setState({
                        startDate: startDate,
                        endDate: endDate,
                        enableValidityDate: true
                    });
                }

                if (this.state.productIds) {
                    const productArray = this.state.productIds.split(',');

                    const selectedProducts = productArray.map(id => {
                        if (id === "1") return { value: '1', label: 'PowerCard 3.5' };
                        if (id === "2") return { value: '2', label: 'PowerCard V4' };
                        if (id === "3") return { value: '3', label: 'V4' };
                        return null;
                    }).filter(product => product !== null);

                    this.setState({ selectedProduct: selectedProducts });
                }

            }, (error) => {
                console.log("ERROR : " + JSON.stringify(error))
                if (error && error.status === 401) {
                    this.toggleFailModal("failureModal", "Your session is expired, please login again.");
                } else {
                    this.toggleFailModal("failureModal", "Internal error please try again later.");
                }
            });
    }

    handleStartDateChange=(e)=> {
        this.setState({ startDate:e.target.value });
    }
    handleEndDateChange=(e)=> {
        this.setState({ endDate:e.target.value });
    }
    onEnableValidityDate(value) {
        this.setState({
            enableValidityDate: !this.state.enableValidityDate
        });
    }
  // enable or disable user
  onEnableUser(value){
      this.setState({
        enabled: !this.state.enabled
      });
      userService.activateUser(this.props.match.params.id,!this.state.enabled)
          .then(response => {
        }, (error) => {
          console.log("ERROR : "+JSON.stringify(error))
          if(error && error.status === 401){
            this.toggleFailModal("failureModal","Your session is expired please login again.");
          }else{
            this.toggleFailModal("failureModal","Internal error please try again later.");
          }
        });
  }
  render() {
    return (
      <>
        <AppNavbar />
                {/* on success model */}
                <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal}
          toggle={() => this.toggleModal("successModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Welcome
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message"> User updated with success</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
         {/* on failure model */}
         <Modal
          className="modal-dialog-centered"
          isOpen={this.state.failureModal}
          toggle={() => this.toggleFailModal("failureModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Sorry!
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>

          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* on confirme dele model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.confirmeDeleteModal}
          toggle={() => this.toggleConfirmDeleteModal("confirmeDeleteModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="confirmDeleteId">
             Confirmation
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleConfirmDeleteModal("confirmeDeleteModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.message}</p>
              </span>
           </Alert>

          </div>
          <div className="modal-footer">
          <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleConfirmDeleteModal("yes")}
            >
              Yes
            </Button>
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleConfirmDeleteModal("confirmeDeleteModal")}
            >
              No
            </Button>
          </div>
        </Modal>
        <main className="profile-page  version-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 "> </section>
          <section className="section main-version-sub-bg main-h">
            <Container>
              <Card className="card-profile mt--300">
              <Row className="justify-content-center">
              <Row className="justify-content-center">
                    </Row>
                  {/*** User Form  */}
                  <Col lg="12" className="edit-user">
                    <h4 className="enable-title">Edit user account</h4>
                    <Form onSubmit={this.handleSubmit}>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label className="update-form-lbl">Username </label>
                            <Input
                              id="exampleFormControlInput1"
                              placeholder="Username"
                              type="text"
                              value={this.state.username}
                              name="username"
                              onChange={(event) => this.handleUserInput(event)}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                        <FormGroup>
                            <label className="update-form-lbl">Email </label>
                            <Input
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                              type="email"
                              value={this.state.email}
                              name="email"
                              onChange={(event) => this.handleUserInput(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                        <FormGroup>
                        <label className="update-form-lbl">FullName </label>
                            <Input
                              id="exampleFormControlInput1"
                              placeholder="FullName"
                              type="text"
                              name="fullName"
                              value={this.state.fullName}
                              onChange={(event) => this.handleUserInput(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                        <FormGroup>
                            <label className="update-form-lbl">Phone number </label>
                            <Input
                              id="exampleFormControlInput1"
                              placeholder="Phone"
                              type="text"
                              name="phone"
                              value={this.state.phone}
                              onChange={(event) => this.handleUserInput(event)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                        {this.state.disableUpdateUser ? (
                            <p></p>
                        ):(
                              <div>
                                {this.state.enabled ? (
                                  <label className="enable-user-title">Active account</label>
                                ):(
                                  <label className="enable-user-title">Inactive account</label>
                                )}
                            <label className="custom-toggle">
                                <input defaultChecked type="checkbox" checked={this.state.enabled}  onChange={e => this.onEnableUser(e.target.value)}/>
                                <span className="custom-toggle-slider rounded-circle" />
                              </label>
                              </div>
                        )}
                        </Col>
                          <Col md="6">
                              <Select
                                  id="productList"
                                  closeMenuOnSelect={false}
                                  value={this.state.selectedProduct}
                                  onChange={(event) => this.updateProductsIds(event)}
                                  isMulti
                                  options={this.state.options}
                              />
                          </Col>


                      </Row>
                    </Form>
                  </Col>
                </Row>
                  <Row>
                      <Col md="6">
                          <Link  to={"/audit/"+this.state.username} className="audit-details">Activity logs</Link>
                      </Col>
                      <Col md="6">
                          <div  className="btn-enable-user-validty">
                              <label className="enable-user-title">Validity rang date</label>
                              <label className="custom-toggle">
                                  <input defaultChecked type="checkbox" checked={this.state.enableValidityDate}  onChange={e => this.onEnableValidityDate(e.target.value)}/>
                                  <span className="custom-toggle-slider rounded-circle" />
                              </label>
                          </div>
                          <div className="account-start-date-label">
                              <label >Start date
                                  <input  onChange={this.handleStartDateChange} disabled={!this.state.enableValidityDate} value={this.state.startDate}  className="account-date-input"  type="date"/>
                              </label>
                          </div>
                          <div className="account-start-date-label">
                              <label >End date
                                  <input onChange={this.handleEndDateChange} disabled={!this.state.enableValidityDate} value={this.state.endDate}   className="account-date-input"  type="date"/>
                              </label>
                          </div>
                      </Col>
                  </Row>
                <Row className="users-buttons">
                  <Col lg="12">
                  {this.state.disableUpdateUser ? (
                    <div>
                      <Button   onClick={this.onUpdateUser}  disabled={!this.isFormValid()}  color="default user-btn" type="button">
                         Update
                      </Button>
                      <Button  color="default user-btn" type="button" onClick={() =>this.previewsPage()}>
                         Return
                    </Button>
                    </div>

                  ):(
                    <div>
                      <Button color="default user-btn" type="button" onClick={() =>this.onDeleteUser()}>
                            Delete
                        </Button>
                        <Button   onClick={this.onUpdateUser}  disabled={!this.isFormValid()}  color="default user-btn" type="button">
                            Update
                        </Button>
                        <Button  color="default user-btn" type="button" onClick={() =>this.previewsPage()}>
                            Return
                        </Button>
                    </div>
                  )}

                  </Col>
                </Row>
              </Card>
            </Container>

          </section>

        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default UsersDetail;
