import { authHeader} from 'helpers/auth-header';
import { authenticationService } from 'services/authentication.service';
export const userService = {
    saveUser,
    searchByParams,
    deleteUserByIds,
    getUserById,
    activateUser,
    updateUser
};

function deleteUserByIds(uuid){
   // return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/users/`+uuid, {
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
   return fetch(`${REACT_BASE_URL}/v1/users/`+uuid, {
      method: 'DELETE',
      headers: authHeader()
    }).then(() => {
       console.log('removed');
    }).catch(err => {
      console.error(err)
    });
}

function searchByParams(keyword,startDate,endDate,status,page,size) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    // return fetch(`${REACT_BASE_URL}/v1/users/search`, requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/users/search?keyword=`+keyword+"&startDate="
         +startDate+"&endDate="+endDate+"&status="+status
         +"&page="+page+"&size="+size, requestOptions)
        .then(response =>{
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    return Promise.reject(response);
                }
            }
            return response.json().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}

function getUserById(id) {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //  return fetch(`${REACT_BASE_URL}/v1/users/`+id, requestOptions)
    //  return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/users/`+id, requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/users/`+id, requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                   return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}

function saveUser(username,email,phone, password,fullName) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username,email,phone, password,fullName })
    };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //return fetch(`${REACT_BASE_URL}/public/v1/users/register`, requestOptions)
    // return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/register`, requestOptions)
    return fetch(`${REACT_BASE_URL}/public/v1/users/register`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function updateUser(id,username,email,phone, password,fullName,productIds,validityRangeDate) {
    const token = authenticationService.currentUserValue;
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization':  `Bearer ${token}`
        },
        body: JSON.stringify({ id,username,email,phone, password,fullName,productIds,validityRangeDate })
    };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //  return fetch(`${REACT_BASE_URL}/v1/users/`, requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/users/`, requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/users/`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return resp;
        }).catch(error =>{
            throw(error);
        });
}

function activateUser(uuid,state) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader()
    };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/users/`+uuid+'/activate/'+state, requestOptions)
    //   return fetch(`${REACT_BASE_URL}/v1/users/`+uuid+'/activate/'+state, requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/users/`+uuid+'/activate/'+state, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
