
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
import ProtectedRoute from './helpers/PrivateRoute';
import Index from "views/Index.js";
import Home from "views/Home.js";
import Login from "views/Login.js";
import SelectApiVersion from "views/SelectApiVersion.js";
import SelectProduct from "views/SelectProduct.js";
import Solution from "views/Solution.js";
import Module from "views/Module.js";
import Register from "views/Register.js";
import Swagger2 from "views/Swagger2.js";
import Swagger from "views/Swagger.js";
import Users from "views/Users.js";
import UsersDetail from "views/UsersDetails.js";
import Help from "views/help";
import About from "./views/About";
import Event from "./views/Event";
import Videos from "./views/Videos";
import PressRelease from "./views/PressRelease";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import WebHook from "./views/WebHook";
import Audit from "./views/Audit";
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact render={props => <Home {...props} />} />
      <Route path="/about" exact render={props => <About {...props} />} />
        <Route path="/upcoming-events" exact render={props => <Event {...props} />} />
        <Route path="/videos" exact render={props => <Videos {...props} />} />
        <Route path="/press-release" exact render={props => <PressRelease {...props} />} />
      <Route
        path="/home"
        exact
        render={props => <Home {...props} />}
      />
      <Route path="/login" exact render={props => <Login {...props} />} />
      <Route path="/forgotPassword" exact render={props => <ForgotPassword {...props} />} />
      <Route path="/changePassword" exact render={props => <ChangePassword {...props} />} />
      <Route path='/swagger-ui' component={Swagger2}>
           <Route path="/swagger-ui/:version/:solution/:module" exact render={props => <Swagger2 {...props} />} />
      </Route>
        <Route path='/webhooks-ui' component={WebHook}>
            <Route path="/webhooks-ui/:version/:solution/:module" exact render={props => <WebHook {...props} />} />
        </Route>
       <Route path='/select-solution' component={Swagger}>
           <Route path="/select-solution/:id" exact render={props => <Solution {...props} />} />
      </Route>
      <Route path='/select-module' component={Swagger}>
           <Route path="/select-module/:id/:name" exact render={props => <Module {...props} />} />
      </Route>
      <Route path='/select-version' component={SelectApiVersion}>
           <Route path="/select-version/:id" exact render={props => <SelectApiVersion {...props} />} />
      </Route>
        <Route path='/audit' component={Audit}>
            <Route path="/audit/:username" exact render={props => <Audit {...props} />} />
        </Route>
      <ProtectedRoute path="/select-product" component={SelectProduct} />
    {/*   <ProtectedRoute path="/select-module" component={Modules} /> */}
      <Route
        path="/register"
        exact
        render={props => <Register {...props} />}
      />
        <Route path="/users" exact render={props => <Users {...props} />} />
        <Route path="/users/:id" exact render={props => <UsersDetail {...props} />} />
        <Route path="/help" exact render={props => <Help {...props} />} />
      <Redirect to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
