
export const emailService = {
    sendEmail,
    requestDemoEmail
};

function sendEmail(subject,address,message,companyName,fullName) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ subject,address,message,companyName,fullName})
    };

    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
      //    return fetch(`${REACT_BASE_URL}/public/v1/users/email`, requestOptions)
     //      return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/email`, requestOptions)
     return fetch(`${REACT_BASE_URL}/public/v1/users/email`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}

function requestDemoEmail(emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone})
    };

    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
      //    return fetch(`${REACT_BASE_URL}/public/v1/users/email`, requestOptions)
     //      return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/email`, requestOptions)
     return fetch(`${REACT_BASE_URL}/public/v1/users/requestDemo`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}