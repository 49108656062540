import {authHeader} from "../helpers/auth-header";

export const auditService = {
    trace,
    searchByParams
};
function trace(action,webService,timestamps,userId,data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action,webService,timestamps,userId,data})
    };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/public/v1/users/email`, requestOptions)
    //      return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/email`, requestOptions)
    return fetch(`${REACT_BASE_URL}/audit/trace`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function searchByParams(keyword,startDate,endDate,page,size) {
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    // return fetch(`${REACT_BASE_URL}/v1/users/search`, requestOptions)
    return fetch(`${REACT_BASE_URL}/audit/events/`+keyword+"?startDate="+startDate+"&endDate="+endDate+"&page="+page+"&pageSize="+size, requestOptions)

        .then(response =>{
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    return Promise.reject(response);
                }
            }
            return response.json().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}