import React from "react";
import { authenticationService } from 'services/authentication.service';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Link } from "react-router-dom";

class ChangePassword extends React.Component {
  
  constructor(props,context) {
            super(props);
            this.state = {
                token:'',
                confirmPassword: '',
                password: '',
                failureModal: false,
                errorMessage: '',
                userLockedModal: false,
              }
            this.ConfirmPassword = this.ConfirmPassword.bind(this);
            this.Password = this.Password.bind(this);
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  ConfirmPassword(event) {
       this.setState({ confirmPassword: event.target.value })
       document.getElementById("errorMessage").innerHTML='';

  }
  Password(event) {
     this.setState({ password: event.target.value })
     document.getElementById("errorMessage").innerHTML='';

  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token')
    this.setState({ token: token })
  }

   isFormValid = () => {
    const {confirmPassword, password} = this.state
     if(password && confirmPassword && password !== confirmPassword){
       document.getElementById("password-input").classList.add("has-error");
       document.getElementById("cpassword-input").classList.add("has-error");
       return false ;
     }else{
       let element1 =  document.getElementById("password-input");
       if(element1) element1.classList.remove("has-error");
       let element2 =  document.getElementById("cpassword-input");
       if(element2) element2.classList.remove("has-error");
     }
     return password && confirmPassword  ;
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.changePassword();
    }
  }

  changePassword = () => {
    let message = '';
    document.getElementById('login-spinner').style.display = 'block';
    document.getElementById('login-spinner').classList.add("login-spinner");
    authenticationService.changePassword(this.state.password,this.state.token)
        .then(
            data => {
              document.getElementById('login-spinner').style.display = 'none';
              this.toggleModal("userLockedModal");
              this.setState({ password:'' })
              this.setState({ confirmPassword:'' })
            },
            (error) => {
              document.getElementById('login-spinner').style.display = 'none';
              let errorType = new String(error);
              if(error !== undefined && error !== null && error!="" && !errorType.includes("TypeError")){
                console.log(JSON.stringify(JSON.parse(error)))
                message = JSON.parse(error);
                document.getElementById("errorMessage").innerHTML=message.errorMessage;
              }else{
                document.getElementById("errorMessage").innerHTML = "Internal error please try again later."
              }
            }
        );
  }
  render() {
    return (
      <>
        <AppNavbar />
                {/* on success model */}
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.userLockedModal}
                  toggle={() => this.toggleModal("userLockedModal")}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    Inactive Account
                    </h5>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("userLockedModal")}
                    >
                      <span aria-hidden={true}>X</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Alert className="alert-default">
                      <span className="alert-inner--text">
                        <strong></strong>
                        <p className="modal-locked-message"> Password changed with success.</p>
                      </span>
                    </Alert>
                  </div>
                  <div className="modal-footer">
                  </div>
             </Modal>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default login-bg">

            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0 create-acc-card">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4 text-register">
                        <small>Reset Password</small>
                      </div>
                      <Form role="form" className="form-position">
                        <FormGroup className="mb-3">
                          <InputGroup id="password-input" className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="password" onChange={this.Password} placeholder="Password" type="password"  value={this.state.password}/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup id="cpassword-input" className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="confirmPassword"
                              placeholder="Confirm password"
                              type="password"
                              value={this.state.confirmPassword}
                              autoComplete="off"
                              onChange={this.ConfirmPassword}
                              onKeyDown={this._handleKeyDown}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4 btn-login-acc"
                            color="primary"
                            type="button"
                            disabled={!this.isFormValid()}
                            onClick={this.changePassword}
                          >
                          
                            Change
                          </Button>
                          <Spinner animation="border" className="login-spinner" id="login-spinner"/>
                          <span id="errorMessage"></span>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small className="forgot-password"></small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link
                          href=""
                          className="text-light"
                          to="/login" tag={Link}

                      >
                        <small className="create-account-link">Login</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew version-sep"></div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ChangePassword;
