import { BehaviorSubject } from 'rxjs';
import jwt from 'jwt-decode';

const currentUserSubject = new BehaviorSubject(sessionStorage.getItem('token'));

export const authenticationService = {
    login,
    resetPassword,
    changePassword,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    getCurrentUser,
    getUserAuthorities,
    getCurrentUserProducts
};

function resetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email})
    };

    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    // return fetch(`${REACT_BASE_URL}/public/v1/users/authenticate`, requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/authenticate`, requestOptions)
    return fetch(`${REACT_BASE_URL}/public/v1/users/reset_password`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function login(username, password) {
    logout();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
     // return fetch(`${REACT_BASE_URL}/public/v1/users/authenticate`, requestOptions)
     //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/authenticate`, requestOptions)
     return fetch(`${REACT_BASE_URL}/public/v1/users/authenticate`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        authenticationService.logout();
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const data = JSON.parse(resp);
            if(data.jwt !== ""){
                sessionStorage.setItem('token',data.jwt);
                currentUserSubject.next(resp);
            }
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function changePassword(password, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password, token })
    };

    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    // return fetch(`${REACT_BASE_URL}/public/v1/users/authenticate`, requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/public/v1/users/authenticate`, requestOptions)
    return fetch(`${REACT_BASE_URL}/public/v1/users/change_password`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function getCurrentUser(){
    const token =  sessionStorage.getItem("token");
    let userName = "";
    if(token){
        const decodedToken = jwt(token);
        userName = decodedToken['user'].username;
    }
  return userName ;
}
function getCurrentUserProducts(){
    const token =  sessionStorage.getItem("token");
    let productsId = "";
    if(token){
        const decodedToken = jwt(token);
        productsId = decodedToken['user'].productIds;
    }
    return productsId ;
}
function getUserAuthorities(){
    const token =  sessionStorage.getItem("token");
    let roles = [];
    let authorities = [];
    if(token){
        const decodedToken = jwt(token);
        roles = decodedToken['user'].roles;
        for (var key in roles) {
            authorities.push(roles[key].authorities)
        }
        return authorities ;
    }
}
function logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('token');
    currentUserSubject.next(null);
    sessionStorage.removeItem("connectApiJwt")
    localStorage.removeItem("authorized")
}