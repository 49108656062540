import React from "react";
import { Link } from "react-router-dom";
import { configService } from 'services/config.service';
import { authenticationService } from 'services/authentication.service';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Spinner,
  Breadcrumb, BreadcrumbItem
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {auditService} from "../services/audit.service";

class SelectApiVersion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      wsConfigs : [],
      errorMessage: ''
    }
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
    this.onConfigLoad();
  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
    if(state === "close"){
      authenticationService.logout();
      window.location.replace("/login");
     }
  };
  onSelectVersion = (version) =>{
    auditService.trace("Select ConnectAPI Version", "Select page", new Date(),authenticationService.getCurrentUser(),version)
        .then(r  => {})
  }
  onConfigLoad = () => {
    sessionStorage.setItem("selected-version",window.location.pathname)
    document.getElementById('version-spinner').style.display = 'block';
    document.getElementById('version-spinner').classList.add("version-spinner");
    configService.getAllWsVersionByProductId(this.props.match.params.id)
    .then(response => {
      let values = response;
      this.setState({
        wsConfigs: values
      });
      document.getElementById('version-spinner').style.display = 'none';
    }, (error) => {
      document.getElementById('version-spinner').style.display = 'none';
        console.log("ERROR : "+JSON.stringify(error))
        if(error && error.status === 401){
          this.toggleFailModal("failureModal","Your session is expired please login again.");
        }else{
          this.toggleFailModal("failureModal","Internal error please try again later.");
        }
    });
  }
  render() {
    return (
      <>
        <AppNavbar />
        {/* on failure model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.failureModal}
          toggle={() => this.toggleFailModal("failureModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Sorry!
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
        <main className="profile-page version-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 main-version-bg">
          <div className="breadcrumbs-wrap">
                    <Breadcrumb className="breadcrumbs-cust">
                        <BreadcrumbItem   active>Home</BreadcrumbItem>
                    </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Releases</BreadcrumbItem>
                        </Breadcrumb>
          </div>  
          </section> 
          <section className="section main-version-sub-bg">
            <Container>
              <Card className="card-profile mt--300">
              <Row className="justify-content-center">
              <Row className="justify-content-center">
                  <Col lg="12">
                      <div >
                      <h2 className="version-title-wrap">PowerCARD Connect’API Releases and Updates</h2>
                      <p className="pg-version-sub-title txt-center"> Welcome to our hub for Connect’API Releases! Here you’ll find information on our key releases to date. Check back for new information every trimester.</p>
                      </div>
                    </Col>
                    </Row>
                  <Col lg="12">
                    <Row className="row-grid">
                    <Spinner animation="border" className="version-spinner" id="version-spinner"/>
                     {this.state.wsConfigs.map(config => (
                     <Col lg="4"  key={config.id}>
                     <Card className="card-lift--hover shadow border-0">
                               <CardBody className="py-5">
                                     <div className="icon icon-shape  rounded-circle mb-4">
                                     {!config.deprecated ? (
                                          <Badge color="primary" pill className="mr-1 version-name">
                                          {config.name}
                                        </Badge>
                                     ):(
                                      <Badge color="primary" pill className="mr-1 version-name v4-latest-version">
                                      {config.name}
                                    </Badge>
                                     )}
                                     </div>
                                     <p className="description mt-3 version-card-desc">
                                       {config.wsDescription.substring(0,170)}.
                                     </p>
                                     {!config.deprecated ? (
                                            <Button
                                                onClick={()=>this.onSelectVersion(config.name)}
                                            disabled={!config.enabled}
                                            className="mt-4 btn-select-module"
                                            color="primary"
                                            to={"/select-solution/"+config.id} tag={Link}
                                          >
                                            Learn More 
                                          </Button>
                                     ):(
                                      <Button
                                      onClick={()=>this.onSelectVersion(config.name)}
                                      disabled={!config.enabled}
                                      className="mt-4 btn-select-module v4-latest-version"
                                      color="default"
                                      to={"/select-solution/"+config.id} tag={Link}
                                       >
                                      <span className="btn-inner--icon">
                                             <i className="ni ni-bold-right v4-btn-select-icon" />
                                            </span>
                                      <span className="btn-inner--text">Learn More</span> 
                                    </Button>
                                     )

                                     }
                                   
                                 </CardBody>
                               </Card>
                              </Col>
                              ))} 

                    </Row>
                  </Col>
                </Row>
              </Card>
            </Container>
           
          </section>
         
        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default SelectApiVersion;
