import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import { authenticationService } from 'services/authentication.service';
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Row,
  Col,
  UncontrolledTooltip, DropdownItem
} from "reactstrap";
import { Alert } from "bootstrap";
import {auditService} from "../../services/audit.service";

class AppNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      permissions: []
    };
 }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    let permis = [];
    if (authenticationService.currentUserValue) { 
      let authorities = authenticationService.getUserAuthorities();
      for( let i = 0; i < authorities.length; i++ ) {
          for( let j = 0; j < authorities.length; j++ ) {
            var obj = authorities[i][j];
            permis.push(obj.permission) ;
          }
      }

      this.setState({
        isLogged: true,
        permissions: permis
      });
     
    }
  }

  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  logout(){
    auditService.trace("Logout", "Click logout", new Date(),authenticationService.getCurrentUser(),null)
        .then(r  => {
          authenticationService.logout();
          window.location.replace("/home");
        },error =>{
          authenticationService.logout();
          window.location.replace("/home");
        })
  }
   
  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  redirectToExternal(new_url) {
    window.open(new_url, '_blank');
  };
  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand  onClick={() => this.scrollToTop()} className="mr-lg-5" to="/" tag={Link}>
                <img
                    className="web-meeting"
                    onClick={()=>this.redirectToExternal('https://wemeeting2023.com/agenda/')}
                    alt="..."
                    src={require("assets/img/brand/webmeeting.png")}
                />
                <img
                  alt="..."
                  src={require("assets/img/brand/hps-logo.png")}
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/home">
                        <img
                          alt="..."
                          src={require("assets/img/brand/hps-logo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                  {this.state.permissions.indexOf("manage_user") > -1 ? (
                     <DropdownToggle nav  to="/users" tag={Link}>
                     <i className="ni ni-ui-04 d-lg-none mr-1" />
                     <a className="nav-link-inner--text"   to="/users" tag={Link}>User Managment</a>
                   </DropdownToggle>
                  ):
                  (
                    <DropdownToggle nav>
                    <i className="ni ni-ui-04 d-lg-none mr-1" />
                    <a className="nav-link-inner--text"></a>
                  </DropdownToggle>
                   )
                  }
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Resources
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/" tag={Link}>Events and webinars</DropdownItem>
                      <DropdownItem to="/" tag={Link}>Changelog</DropdownItem>
                      <DropdownItem to="/videos" tag={Link}>Videos</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Community
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem  to="/upcoming-events" tag={Link}>HPS at 2022 Events </DropdownItem>
                      <DropdownItem to="/upcoming-events" tag={Link}>Previous Events in 2021</DropdownItem>
                      <DropdownItem to="/press-release" tag={Link}>Press Releases </DropdownItem>
                    </DropdownMenu>

                  </UncontrolledDropdown>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      About
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem to="/about" tag={Link}>About HPS</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto social-media-items" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.linkedin.com/company/hps"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Linkedin
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Like us on linkedin
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.youtube.com/channel/UCG8SC8iSUZlsqR9oOyvG7nA"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-youtube" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Youtube
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Youtube
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/HPS_worldwide"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem className="d-none d-lg-block ml-lg-4">
        
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
                 <label className="current-user">{authenticationService.getCurrentUser()}</label>
                  {this.state.isLogged ? (
                     <Button className="btn-sign-out" color="warning" type="button"  onClick={this.logout}>
                     Sign out {this.state.isLogged}
                    </Button>
                  ) : (
                    <Button className="btn-create-acc" color="warning" type="button"  to="/register" tag={Link}>
                      Create an account
                   </Button>
                  )}
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default AppNavbar;
