import React from "react";
import SwaggerUI from "swagger-ui-react"
import { Link } from "react-router-dom";
import authriseBtnImage from '../assets/img/btn-authorise.png';
import { saveAs } from "file-saver";

import { authenticationService } from 'services/authentication.service';
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { configService } from 'services/config.service';
// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Modal,
  Button,
  Alert,
  Col,
  Badge,
  Breadcrumb, BreadcrumbItem,UncontrolledTooltip 
} from "reactstrap";
class Swagger extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      notificationModal: false,
      encryptionModal:false
    }
    this.doSearch = this.doSearch.bind(this);
    this.onComplete = this.onComplete.bind(this);
  }
  saveFile = () => {
        saveAs(
            process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/api.yaml','api.yaml'
        );
      saveAs(
          process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/request.yaml','request.yaml'
      );
      saveAs(
          process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/response.yaml','response.yaml'
      );
      saveAs(
          process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/aggregate.yaml','aggregate.yaml'
      );
    };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  encryptRequest(){
    var requestContent = document.getElementById("toolbox-modal-encrypt-textarea");
         if(requestContent && requestContent.value!=null && requestContent.value !="")
              requestContent ='{"encryptedData":'+JSON.stringify(requestContent.value)+'}'
              let reponseContainer = document.getElementById("toolbox-modal-encrypt-textarea");
              configService.encyptData(requestContent).then(response => {
                    if(reponseContainer){
                      reponseContainer.value=response
                    }
                 }, (error) => {
                  reponseContainer.value="could not decrypt data : "+JSON.stringify(error)
                 });
  }
  decryptRequest(){
    var requestContent = document.getElementById("toolbox-modal-encrypt-textarea");
         if(requestContent && requestContent.value!=null && requestContent.value !=""){
          let reponseContainer = document.getElementById("toolbox-modal-encrypt-textarea");
          let textToDecrypt = reponseContainer.value;
          configService.decyptData(textToDecrypt).then(response => {
                if(reponseContainer){
                  reponseContainer.value=response
                }
             }, (error) => {
              reponseContainer.value="could not decrypt data : "+JSON.stringify(error)
             });
         }
  }
  copyToClipboard(){
    var requestContent = document.getElementById("toolbox-modal-encrypt-textarea");
    if(requestContent && requestContent.value!=null && requestContent.value !="")
    {
      navigator.clipboard.writeText(requestContent.value)
    }
  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
    
  }
  onComplete(){
      if(document.getElementsByClassName("authorize")[0]){
        document.getElementsByClassName("authorize")[0].innerHTML = '<span>Setting an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';
      }
      else{
      return
      }
      let token = sessionStorage.getItem("connectApiJwt")
      if(token){
       document.getElementsByClassName("authorize")[0].innerHTML = '<span>Reset an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';

      }       
      const url = new URL(window.location.href);
      const apiName = url.searchParams.get('api');
      if(apiName !== null){
        this.doSearch(apiName,"##9e191d")
      }
      // add encrypt decrypt buttons events
      var classname = document.getElementsByClassName("opblock-tag-section");
      var onclickEncryptDecrypt = function() {
        window.onclick = e => {
          // Decrypt reponse button clicked
           if(e.target!= null && e.target.textContent=="Decrypt Response"){
             let response = this.querySelector('.responses-table .highlight-code code').textContent;
             if(response){
                    configService.decyptData(response)
                      .then(response => {
                                if(response != null){
                                  var modal = document.getElementById("decryptModal");
                                  if(modal){
                                      modal.style.visibility = "visible";
                                      var modalContent =   document.getElementById('decrypted-response-json');
                                      modalContent.innerHTML='<div class="decrypted-response"><h5 style="color:#ffa600;margin-left: 7px;margin-top: 3px;">Decrypted response : </h5><span id="decry-resp-json" class="language-json" style="color: rgb(162, 252, 162);">'+response.replace(/\\/g, "").replace('{"encryptedData":"',"").replace('"}',"")+'</span></div>'
                                    }                    
                                }
                              }, (error) => {
                                  console.log("could not decrypt data : "+JSON.stringify(error))
                              });
                        }
              }
              // Encrypted button clicked
             else  if(e.target!= null && e.target.textContent=="Encrypt Request"){
                                 var modal = document.getElementById("encryptModal");
                                  if(modal){
                                     modal.style.visibility = "visible";
                                  }
      }else  if(e.target!= null && e.target.textContent=="Execute"){
          document.querySelectorAll('.renderedMarkdown > div > div > button').forEach(elem => {
            elem.style.display = "block";
          });
        }
        else  if(e.target!= null && e.target.textContent=="Clear"){
          document.querySelectorAll('.renderedMarkdown > div > div > button').forEach(elem => {
            elem.style.display = "none";
          });
        }
        else  if(e.target!= null && e.target.textContent=="Try it out "){
          document.querySelectorAll('.renderedMarkdown > div > div > button').forEach(elem => {
            elem.style.display = "none";
          });
        }
      } 
    };
      for (var i = 0; i < classname.length; i++) {
          classname[i].addEventListener('click', onclickEncryptDecrypt, false);
      }

      var all = document.getElementsByClassName('opblock-tag-section');
      for(var i=0;i<all.length;i++){
        all[i].onclick=function(){
        }
      }
      document.getElementById("decrypt-close").onclick = function() { 
        var modal = document.getElementById("decryptModal");
        modal.style.visibility = "hidden";
       };
       document.getElementById("copy-decrypt-resp").onclick = function() { 
        var textToCopy = document.getElementById("decry-resp-json");
        navigator.clipboard.writeText(textToCopy.textContent)
       };
       document.getElementById("copy-encrypt-resp").onclick = function() { 
        var textToCopy = document.getElementById("encrypted-response-json");
        navigator.clipboard.writeText(textToCopy.value)
       };
       document.getElementById("encrypt-close").onclick = function() {
        var modal = document.getElementById("encryptModal");
        modal.style.visibility = "hidden";
       };
       document.getElementById("modal-cls-btn").onclick = function() { 
        let requestContent = document.getElementById("textarea-encrypt-req");
        let reponseContainer = document.getElementById("encrypted-response-json");
        if(requestContent && reponseContainer){
          requestContent.value ='';
          reponseContainer.value='';
        }
       };
       const el = document.getElementById("testCase1");
       document.getElementById("modal-encrypte-btn").onclick = function() { 
        var requestContent = document.getElementById("textarea-encrypt-req");
         if(requestContent && requestContent.value!=null && requestContent.value !="")
              requestContent ='{"encryptedData":'+JSON.stringify(requestContent.value)+'}'
              configService.encyptData(requestContent).then(response => {
                    let reponseContainer = document.getElementById("encrypted-response-json");
                    if(reponseContainer){
                      reponseContainer.value=response
                    }
                 }, (error) => {
                   console.log("could not decrypt data : "+JSON.stringify(error))
                 });
          };
     // -- end 
    document.addEventListener('click', function(e) {
      e = e || window.event;
      var target = e.target || e.srcElement,
          text = target.textContent || target.innerText;   
            if(text && text=="Authorize"){
                let element =  document.querySelector(".swagger-ui .auth-container input[type=text]")
                if(element && element.value != null && element.value !="" && element.value!=undefined){
                  sessionStorage.setItem("connectApiJwt",element.value)
                  if( document.getElementsByClassName("authorize")[0])
                  {
                    document.getElementsByClassName("authorize")[0].innerHTML = '<span>Reset an Access Token </span><svg width="20" height="20"><use href="#unlocked" xlink:href="#unlocked"></use></svg>';
                  }
                }
           } 
        // load multiple test cases 
        const elementClicked = document.getElementById("testCase1");
        if(elementClicked && elementClicked.getAttribute('listener') !== 'true'){
            elementClicked.addEventListener('change', (event) => {
            elementClicked.setAttribute('listener', 'true');
                  let code = document.querySelector(".body-param__text");
                  if(code){
                        let json = ""
                        if(event.target && event.target.value=="bank"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkJBTksiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="account_type_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUNDT1VOVF9UWVBFX0xJU1QiLAogICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="action_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUNUSU9OX0xJU1QiLAogICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="action_table"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkFDVElPTl9UQUJMRSIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }
                        else if(event.target && event.target.value=="address_type_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgInBhcmFtZXRlck5hbWUiOiAiQUREUkVTU0VTX1RZUEVfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQoK"))), null, 2));
                        }  
                        else if(event.target && event.target.value=="branch"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkJSQU5DSCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="card_batch_status"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfQkFUQ0hfU1RBVFVTIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cgo="))), null, 2));
                        } 
                        else if(event.target && event.target.value=="card_product"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfUFJPRFVDVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }      
                        else if(event.target && event.target.value=="card_status"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBUkRfU1RBVFVTIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="card_reason"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBU0VfUkVBU09OIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }
                        else if(event.target && event.target.value=="card_type"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNBU0VfVFlQRSIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="city"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNJVFkiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }   
                        else if(event.target && event.target.value=="country"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNPVU5UUlkiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }  
                        else if(event.target && event.target.value=="cr_profile"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNSX1BST0ZJTEUiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }   
                        else if(event.target && event.target.value=="currency_table"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNVUlJFTkNZX1RBQkxFIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="document_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkRPQ1VNRU5UX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }   
                        else if(event.target && event.target.value=="language_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkxBTkdVQUdFX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }   
                        else if(event.target && event.target.value=="mcc"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIk1DQyIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        } 
                        else if(event.target && event.target.value=="chain"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIkNIQUlOIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="owner_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIk9XTkVSX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }  
                        else if(event.target && event.target.value=="plastic_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBMQVNUSUNfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="processing_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBST0NFU1NJTkdfTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        } 
                        else if(event.target && event.target.value=="protocol_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlBST1RPQ09MX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        } 
                        else if(event.target && event.target.value=="region"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlJFR0lPTiIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        } 
                        else if(event.target && event.target.value=="status_reason_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlNUQVRVU19SRUFTT05fTElTVCIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        }   
                        else if(event.target && event.target.value=="title_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRJVExFX0xJU1QiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0K"))), null, 2));
                        }
                        else if(event.target && event.target.value=="transaction_status"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRSQU5TQUNUSU9OX1NUQVRVUyIsCiAgICAgInBhcmFtZXRlck1vZGUiOiAiUCIsCiAgICAgICJwYXJhbWV0ZXJDb2x1bW5MaXN0IjogWwogICAgICAgICAgIkNvZGUiLCAiTGFiZWwiCiAgICAgIF0sCiAgICAgICJwYXJhbWV0ZXJDb25kaXRpb25MaXN0IjogWwogICAgICBdCiAgICB9CiAgXSwKICAicmVxdWVzdEluZm8iOiB7CiAgICAicmVxdWVzdFVJRCI6ICI2OTQ4REY4MC0xNEJELTRFMDQtODg0Mi03NjY4RDlDMDAxRjUiLAogICAgInJlcXVlc3REYXRlIjogIjIwMjAtMDItMDVUMDA6MDA6MDAiLAogICAgInVzZXJJRCI6ICJ1c2VyMDAxIgogIH0KfQo="))), null, 2));
                        } 
                        else if(event.target && event.target.value=="transaction_definition"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlRSQU5TQUNUSU9OU19ERUZJTklUSU9OIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="vip_list"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIlZJUF9MSVNUIiwKICAgICAicGFyYW1ldGVyTW9kZSI6ICJQIiwKICAgICAgInBhcmFtZXRlckNvbHVtbkxpc3QiOiBbCiAgICAgICAgICAiQ29kZSIsICJMYWJlbCIKICAgICAgXSwKICAgICAgInBhcmFtZXRlckNvbmRpdGlvbkxpc3QiOiBbCiAgICAgIF0KICAgIH0KICBdLAogICJyZXF1ZXN0SW5mbyI6IHsKICAgICJyZXF1ZXN0VUlEIjogIjY5NDhERjgwLTE0QkQtNEUwNC04ODQyLTc2NjhEOUMwMDFGNSIsCiAgICAicmVxdWVzdERhdGUiOiAiMjAyMC0wMi0wNVQwMDowMDowMCIsCiAgICAidXNlcklEIjogInVzZXIwMDEiCiAgfQp9Cg=="))), null, 2));
                        }  
                        else if(event.target && event.target.value=="withdrawl_reason_code"){
                          json =  JSON.parse(JSON.stringify(decodeURIComponent(escape(atob("ewogICJwYXJhbWV0ZXJMaXN0IjogWwogICAgewogICAgICJwYXJhbWV0ZXJOYW1lIjogIldJVEhEUkFXQUxfUkVBU09OX0NPREUiLAogICAgICJwYXJhbWV0ZXJNb2RlIjogIlAiLAogICAgICAicGFyYW1ldGVyQ29sdW1uTGlzdCI6IFsKICAgICAgICAgICJDb2RlIiwgIkxhYmVsIgogICAgICBdLAogICAgICAicGFyYW1ldGVyQ29uZGl0aW9uTGlzdCI6IFsKICAgICAgXQogICAgfQogIF0sCiAgInJlcXVlc3RJbmZvIjogewogICAgInJlcXVlc3RVSUQiOiAiNjk0OERGODAtMTRCRC00RTA0LTg4NDItNzY2OEQ5QzAwMUY1IiwKICAgICJyZXF1ZXN0RGF0ZSI6ICIyMDIwLTAyLTA1VDAwOjAwOjAwIiwKICAgICJ1c2VySUQiOiAidXNlcjAwMSIKICB9Cn0KCg=="))), null, 2));
                        }                                                              
                        let requestContainer =  document.querySelector(".body-param");
                        if(requestContainer){
                        var input = document.querySelector('.body-param__text');
                        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, "value").set;
                        nativeInputValueSetter.call(input, json);
                        var ev2 = new Event('input', { bubbles: true});
                        input.dispatchEvent(ev2);
                        }
                  }
          },true);
        }
  }, false);
 }
   insertAfter(referenceNode, newNode) {
      referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }

  doSearch(text, backgroundColor){
    if (window.find && window.getSelection) {
        document.designMode = "on";
        var sel = window.getSelection();
        sel.collapse(document.body, 0);

        while (window.find(text)) {
            document.execCommand("HiliteColor", false, backgroundColor);
            var selectedElement = window.getSelection().focusNode.parentNode;
                selectedElement.style.color = '#8898aa';

                if (selectedElement.fireEvent) {
                  selectedElement.fireEvent('on' + "click");
                } else {
                  var evObj = document.createEvent('Events');
                  evObj.initEvent("click", true, false);
                  selectedElement.dispatchEvent(evObj);
                }

            sel.collapseToEnd();
        }
        document.designMode = "off";
    }
  }
  render() {
    return (
      <>
        <AppNavbar />
        <div id="decryptModal" class="decrypt-modal">
          <div class="decrypt-modal-content">
            <span id="decrypt-close"class="decrypt-close">&times;</span>
            <code className="language-json">
              <div id="decrypted-response-json">

              </div>
              <div>
                 <Button id="copy-decrypt-resp" className="btn-icon btn-2 copy-decrypted-response" outline  color="default" type="button">
                    <span className="btn-inner--icon copy-decrypted-response-icon">
                      <i className="ni ni-single-copy-04" id="tooltip297043631" />
                    </span>
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip297043631"
                  >
                    Copy the decrypted response
                  </UncontrolledTooltip>
              </div>
            </code>
          </div>
        </div>
        <div id="encryptModal" class="encrypt-modal">
          <div class="encrypt-modal-content">
            <span id="encrypt-close"class="encrypt-close">&times;</span>
            <label className="textarea-encrypt-req-label">Request to encrypt : </label>
            <textarea id="textarea-encrypt-req" className="textarea-encrypt-req"></textarea>
            <label className="textarea-encrypted-req-label">Encrypted Request : </label>
            <textarea readOnly="true" id="encrypted-response-json" className="textarea-encrypted-req"></textarea>
            <div>
                 <Button id="copy-encrypt-resp" className="btn-icon btn-2 copy-enecrypted-request" outline  color="default" type="button">
                    <span className="btn-inner--icon copy-decrypted-response-icon">
                      <i className="ni ni-single-copy-04" id="tooltip297043631" />
                    </span>
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="tooltip297043631"
                  >
                    Copy the decrypted response
                  </UncontrolledTooltip>
              </div>
              <div className="modal-encrypt-btn-wrap">
                <button id="modal-cls-btn" className="modal-encrypte-cls">Clear</button>
                <button id="modal-encrypte-btn" className="modal-encrypte-btn">Encrypt</button>
              </div>
          </div>
        </div>
        <main ref="main">
          <section className="section section-shaped section-lg section-bg">
          <Row>
              <div className="breadcrumbs-swagger-wrap">
                    <Breadcrumb className="breadcrumbs-cust breadcrumbs-swagger">
                        <BreadcrumbItem  active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.goBack()}>Releases</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.goBack()}>Solutions</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.goBack()}>Modules</Link></BreadcrumbItem>
                          <BreadcrumbItem active>OpenAPI 3.1.0</BreadcrumbItem>
                        </Breadcrumb>
              </div>  
              </Row>
            <div className="shape shape-style-1 bg-gradient-default swagger-wrap">
            </div>
            <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
              <Row>
              <Col md="4" className="search-input-col">
              <FormGroup className="search-input-warp">
                <Input
                  className="search-input"
                  placeholder="Search"
                  type="text"
                  name="keyword"
                  value={this.state.keyword}
                  onChange={(event) => this.handleUserInput(event)}
                  />
                    <button className="btn btn-warning btn-search" onClick={() => this.doSearch(this.state.keyword,"red")}>Search</button>
              </FormGroup>
            </Col>
            </Row>
            
              <div className="ws-list">
              <span class="icon icon-lg icon-shape icon-shape-success shadow rounded-circle ws-list-icon-bg">
              <img className="ws-list-cat-icon" src={require("assets/img/ws.png")}/>
              </span>
                <h3>REST API Reference </h3>
                <img className="line-sep-swagger"  src={require("assets/img/br.png")}/>
                <i onClick={() => this.toggleModal("encryptionModal")} className="ni ni-settings toolbox"></i>
                <i onClick={() => this.saveFile()} className="ni ni-cloud-download-95 toolbox yaml-download"></i>
              </div>
               <Modal
                    className="modal-dialog-centered toolbox-modal"
                    isOpen={this.state.encryptionModal}
                    toggle={() => this.toggleModal("encryptionModal")}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="encryptionModalLabel">
                      Request  : 
                      </h5>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("encryptionModal")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                        <textarea className="toolbox-modal-encrypt-textarea" id="toolbox-modal-encrypt-textarea" ></textarea>
                       
                    </div>
                    <div className="modal-footer toolbox-modal-encrypt-footer">
                      
                    <Button size="sm" onClick={()=>this.copyToClipboard()}  outline  color="secondary" type="button">
                    <span  color="secondary">
                      <i className="ni ni-single-copy-04" id="tooltip297043631" />
                    </span>
                     </Button>
                      <Button
                        color="secondary"
                        data-dismiss="modal"
                        size="sm"
                        type="button"
                        onClick={() => this.toggleModal("encryptionModal")}
                      >
                        Close
                      </Button>
                      <Button onClick={()=>this.encryptRequest()} size="sm" color="secondary" type="button">
                        Encrypt
                      </Button>
                      <Button onClick={()=>this.decryptRequest()} size="sm" color="secondary" type="button">
                        Decrypt
                      </Button>
                    </div>
                  </Modal>
            <SwaggerUI persistAuthorization="true" url={process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/api.yaml'}   docExpansion ="none" onComplete={() => this.onComplete()} requestInterceptor={req =>  {
                    const bearerToken = sessionStorage.getItem('connectApiJwt');
                    if (!bearerToken) {
                      console.log("No token found")
                      return req;
                    } else {
                      req.headers.Authorization = `Bearer ${bearerToken}`;
                      return req;
                    }
                  }
              }/>
          <div className="separator separator-bottom separator-skew version-sep"></div>

          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Swagger;
