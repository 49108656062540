import React from "react";
import { Link } from "react-router-dom";
import { emailService} from 'services/email.service';
import Player from '../views/Player'
// reactstrap components
import {
    Badge,
    Button,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Modal,
    Collapse,
    Nav, NavItem, NavLink,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Breadcrumb, BreadcrumbItem,
    Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Collapsible from 'react-collapsible';
class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            playVideo:false,
            emailSubject:'',
            fullName:'',
            companyName:'',
            emailAddress:'',
            emailBody:'',
            jobTitle:'',
            phone:'',
            successEmailModal:false
        }
    }
    playVideo = () =>{
        this.toggleModal("playVideo")
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    onSuccessSendEmail = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };
    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value });
    }
    handleSubmit= (e) => {
        e.preventDefault();
        this.sendRequestDemo();

    }
    sendRequestDemo = () => {
        const {emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone} = this.state
        emailService.requestDemoEmail(emailSubject, fullName, companyName, emailAddress,emailBody,jobTitle,phone)
            .then(
                response => {
                    this.onSuccessSendEmail("successEmailModal");
                    this.setState({
                        emailSubject:'',
                        fullName:'',
                        companyName:'',
                        emailAddress:'',
                        emailBody:'',
                        jobTitle: '',
                        phone: ''
                    });
                },
                (error) => {
                    let errorType = new String(error);
                    if(error !== undefined && error !== null && !errorType.includes("TypeError")){
                        console.log(JSON.stringify(JSON.parse(error)))
                    }else{
                        //this.toggleFailModal("failureModal","Internal error please try again later.");
                    }

                }
            );
    }
    checkForm = () => {
        let isValidSubject= false;
        let isValidJob= false;
        let isValidEmail= false;
        let isValidPhone= false;
        const {emailSubject, emailAddress,jobTitle,phone} = this.state
        if(!emailSubject){
            if(document.getElementById("subject-input"))
                document.getElementById("subject-input").classList.add("has-error");
            return false ;
        }else{
            let element1 =  document.getElementById("subject-input");
            if(element1) element1.classList.remove("has-error");
            isValidSubject = true
        }
        if(!emailAddress){
            if(document.getElementById("email-input"))
                document.getElementById("email-input").classList.add("has-error");
            return false ;
        }else{
            let element1 =  document.getElementById("email-input");
            if(element1) element1.classList.remove("has-error");
            isValidEmail = true
        }
        if(!jobTitle){
            if(document.getElementById("job-input"))
                document.getElementById("job-input").classList.add("has-error");
            return false ;
        }else{
            let element1 =  document.getElementById("job-input");
            if(element1) element1.classList.remove("has-error");
            isValidJob = true
        }
        if(!phone){
            if(document.getElementById("phone-input"))
                document.getElementById("phone-input").classList.add("has-error");
            return false ;
        }else{
            let element1 =  document.getElementById("phone-input");
            if(element1) element1.classList.remove("has-error");
            isValidPhone = true
        }
        return isValidPhone && isValidEmail && isValidJob && isValidSubject ;
    }
    isFormValid = () => {
        let isValidSubject= false;
        let isValidJob= false;
        let isValidEmail= false;
        let isValidPhone= false;
        const {emailSubject, emailAddress,jobTitle,phone} = this.state
        if(!emailSubject){
            if(document.getElementById("subject-input"))
                return false ;
        }else{
            isValidSubject = true
        }
        if(!emailAddress){
            return false ;
        }else{
            isValidEmail = true
        }
        if(!jobTitle){
            return false ;
        }else{
            isValidJob = true
        }
        if(!phone){
            return false ;
        }else{
            isValidPhone = true
        }
        return isValidPhone && isValidEmail && isValidJob && isValidSubject ;
    }
    render() {
        return (
            <>
                {/* play video model */}
                <Modal
                    className="modal-dialog-centered demo-video-modal"
                    isOpen={this.state.playVideo}
                    toggle={() => this.toggleModal("playVideo")}
                >
                    <div className="modal-header">
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("playVideo")}
                        >
                            <span aria-hidden={true}>X</span>
                        </button>
                    </div>
                    <div className="modal-body demo-video-modal-height">
                        <Alert className="alert-default react-player-warp">
                            {/*    <video width="480" height="240"  className="demo-video-container" controls autoplay controlsList="nodownload">
                        <source src={require("assets/video/ConnectAPI.mp4")} />
                        </video>  */}
                            <Player  className="demo-video-container"/>
                        </Alert>
                    </div>
                    <div className="modal-footer">
                    </div>
                </Modal>
                {/* on success model */}
                <Modal
                    className="modal-dialog-centered"
                    isOpen={this.state.successEmailModal}
                    toggle={() => this.onSuccessSendEmail("successEmailModal")}
                >
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Thank you
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.onSuccessSendEmail("successEmailModal")}
                        >
                            <span aria-hidden={true}>X</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message">We will contact you shortly to book a convenient time.</p>
              </span>
                        </Alert>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                            className="btn-close-modal"
                            onClick={() => this.onSuccessSendEmail("successEmailModal")}
                        >
                            Close
                        </Button>
                    </div>
                </Modal>
                <AppNavbar />

                <main className="profile-page demo-page" ref="main">
                    <section className="section-shaped my-0  ">
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb className="breadcrumbs-cust">
                                <BreadcrumbItem   active>Home</BreadcrumbItem>
                            </Breadcrumb>
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                                <BreadcrumbItem active>Guide</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </section>
                    <section className="section demo-section">

                        <Container>
                            <Row className="justify-content-center">
                                <h3>Established in 1995...</h3>
                                <p className="about-txt1">HPS has grown rapidly to become one of the leading global cards and payments technology companies, with over 450 clients in 90 countries, providing them with cutting edge payments technology.</p>
                                <p className="about-txt1">HPS’ mission from the start has been to provide its clients with innovative technology solutions that transcend the entire payments value chain, enabling them to innovate rapidly and bring new products to market with ease and efficiency using HPS’ PowerCARD software, which is increasingly seen as the future for the payments industry. The PowerCARD suite is fully integrated with all international payment networks, and supports all major back-office payments functions.</p>
                                <img className="about-img" src={require('../assets/img/brand/about.png')}/>
                                <p className="about-txt1">HPS has offices located in major business centres in Africa, Europe, Middle East and Asia, a world-class management team, and a proven reputation as one of the most powerful payments technology providers in the industry.</p>
                                <img className="about-img-world" src={require('../assets/img/HPS_WORLD_MAP_old.jpg')}/>
                            </Row>
                        </Container>
                    </section>

                </main>
                <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
                <SimpleFooter />
            </>
        );
    }
}

export default About;
