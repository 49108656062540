import React from "react";
import { Link } from "react-router-dom";
import { userService } from 'services/user.service';
import { authenticationService } from 'services/authentication.service';
import "jspdf-autotable";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Breadcrumb, BreadcrumbItem
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import DataTable from 'react-data-table-component';
import {auditService} from "../services/audit.service";
import jsPDF from "jspdf";

type DataRow = {
    userId: string;
    action: string;
    webService: string;
    data: string;
    timestamps: string;

};

const columns: TableColumn<DataRow>[] = [
    {
        name: 'UserName',
        selector: row => row.userId,
        sortable: true,
    },
    {
        name: 'Action',
        selector: row => row.action,
        sortable: true,
    },
    {
        name: 'Component',
        selector: row => row.webService,
        sortable: true,
    },
    {
        name: 'Data',
        selector: row => row.data,
        sortable: true,
    },
    {
        name: 'Timestamps',
        selector: row => row.timestamps,
        sortable: true,
    },
];
// data provides access to your row data
const ExpandedComponent: React.FC<ExpanderComponentProps<DataRow>> = ({ data }) => {
    return <pre>{JSON.stringify(data)}</pre>;
};
class Audit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      users : [],
      usersList : [],
      activityLogs : [],
      errorMessage: '',
      toggledClearRows: false,
      toggleFailModal: false,
      totalRows:0,
      loading:false,
      startDate:new Date().toISOString().slice(0,10),
      endDate:new Date().toISOString().slice(0,10),
      userState:'all',
      keyword:''
    }
    this.getEventsByUser = this.getEventsByUser.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) {
      this.props.history.push('/');
    }else{
      let permissions = [];
      let authorities = authenticationService.getUserAuthorities();
      for( let i = 0; i < authorities.length; i++ ) {
          for( let j = 0; j < authorities.length; j++ ) {
            var obj = authorities[i][j];
            permissions.push(obj.permission) ;
          }
      }
      if(permissions.indexOf("manage_user") <= -1){
        this.props.history.push('/');
      }

    }
    this.getEventsByUser(0,10);

  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
    if(state === "close"){
      authenticationService.logout();
      this.props.history.push("/login");
     }
  };

  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
   if(state === "close"){
    window.location.reload();
    }
  };
  handleChange=(e)=> {
    this.setState({ keyword: e.target.value });
  }
  handleStartDateChange=(e)=> {
    this.setState({ startDate:e.target.value });
  }
  handleEndDateChange=(e)=> {
    this.setState({ endDate:e.target.value });
  }
  filterAccount=(e)=>{
    this.setState({ userState:e.target.value });
  }
   exportAllUserActivityLogs(){
        const keyword   = this.props.match.params.username;
        const startDate = this.state.startDate ;
        const endDate   = this.state.endDate ;
        auditService.searchByParams(keyword,startDate,endDate,0,10000)
            .then(response => {
                let values = response.content;
                this.exportPDF(values)

            }, (error) => {
                this.setState({ loading: false });
                console.log("ERROR : "+JSON.stringify(error))
                if(error && error.status === 401){
                    this.toggleFailModal("toggleFailModal","Your session is expired please login again.");
                }else{
                    this.toggleFailModal("toggleFailModal","Internal error please try again later.");
                }
            });

    }
 getEventsByUser(page,size){
    const keyword   = this.props.match.params.username;
    const startDate = this.state.startDate ;
    const endDate   = this.state.endDate ;
   this.setState({ loading:true });
     auditService.searchByParams(keyword,startDate,endDate,page,size)
        .then(response => {
          let values = response.content;
          this.setState({ totalRows: response.totalElements });
          this.setState({
            users: values,
            usersList: values,
            loading:false
          });
        }, (error) => {
          this.setState({ loading: false });
          console.log("ERROR : "+JSON.stringify(error))
          if(error && error.status === 401){
            this.toggleFailModal("toggleFailModal","Your session is expired please login again.");
          }else{
            this.toggleFailModal("toggleFailModal","Internal error please try again later.");
          }
        });

  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.getEventsByUser(0,10);
    }
  }
  selectRows = state => {
    this.setState({ selectedRows: state.selectedRows });
  }
  onSelectRow = row => {
    this.props.history.push("/users/"+row.id);
  }
  handlePerRowsChange = page =>{
    this.getEventsByUser(page-1,10);
  }
  exportPDF(activityLogs){
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "landscape"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
      doc.setFont("helvetica");
      doc.setFontSize(12);

      const title = "Sandbox ConnectAPI : Activity logs Report";
      const headers = [["UserId", "Action","Component","Data","Timestamps"]];

      const data = activityLogs.map(elt=> [elt.userId, elt.action,elt.webService,elt.data,elt.timestamps]);

      let content = {
          styles: { fontSize:9 },
          startY: 95,
          head: headers,
          body: data,
          rowPageBreak: 'avoid',
          showFoot: 'everyPage'
      };

      doc.text(title, marginLeft, 40);
      doc.text(`Username :  ${this.props.match.params.username}`, marginLeft, 60);
      doc.text(`From :  ${this.state.startDate}  To : ${this.state.endDate}  `, marginLeft, 80);
      doc.autoTable(content);
      doc.text(`© ${new Date().getFullYear()} Sandbox ConnectAPI HPS.`,marginLeft,doc.internal.pageSize.height - 30);


      doc.save(`rapport_activity_log_${this.props.match.params.username}.pdf`)
    }

    render() {
    return (
      <>
        <AppNavbar />
                {/* on success model */}
                <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal}
          toggle={() => this.toggleModal("successModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Welcome
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message"> User deleted with success</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
        {/* on failure model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.toggleFailModal}
          toggle={() => this.toggleFailModal("toggleFailModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Error
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("toggleFailModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
          <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("toggleFailModal")}
            >
              Ok
            </Button>
          </div>
        </Modal>
        <main className="profile-page version-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 main-version-bg ">
          <div className="breadcrumbs-wrap">
                    <Breadcrumb className="breadcrumbs-cust">
                        <BreadcrumbItem   active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Audit</BreadcrumbItem>
                        </Breadcrumb>
          </div> 
             </section>
          <section className="section main-version-sub-bg main-h">
            <Container className="container-users">
              <Card className="card-profile mt--300">
              <Row className="justify-content-center">
              <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="audit-search-warp">
                      <Button disabled={this.state.usersList.length==0}  onClick={() => this.exportAllUserActivityLogs()}  className="btn-icon btn-3 search-btn"  color="default" outline type="button" size="sm">
                          <span className="btn-inner--icon">
                            <i className="fa fa-file-pdf-o search-btn-icon" />
                          </span>
                          <span className="btn-inner--text"></span>
                        </Button>
                        <Button onClick={()=>this.getEventsByUser(0,10)}  className="btn-icon btn-3 search-btn"  color="default" outline type="button" size="sm">
                          <span className="btn-inner--icon">
                            <i className="fas fa-search search-btn-icon" />
                          </span>
                            <span className="btn-inner--text"></span>
                        </Button>
                        <label className="search-user-date-label">Start date
                          <input  onChange={this.handleStartDateChange} value={this.state.startDate}  className="search-date-input"  type="date"/>
                        </label>
                        <label className="search-user-date-label">End date
                          <input onChange={this.handleEndDateChange} value={this.state.endDate}  className="search-date-input"  type="date"/></label>

                    </div>
                    </Col>
                    </Row>
                  <Col lg="12">
                    <Row className="row-grid audit-wrap">
                        <DataTable
                            id="content"
                            title="Activity logs"
                            columns={columns}
                            data={this.state.users}
                            pagination="true"
                            highlightOnHover="true"
                            pointerOnHover="true"
                            data-tag="allowRowEvents"
                            Clicked
                            dense
                            responsive
                            expandableRows
                            striped="true"
                            paginationServer
                            paginationTotalRows={this.state.totalRows}
                            selectableRowsHighlight="true"
                            onChangePage={this.handlePerRowsChange}
                            progressPending={this.state.loading}
                            expandableRowsComponent={ExpandedComponent}
                        />
                    </Row>
                    <Row className="row-grid users-buttons">
                    <Col lg="12">
                     
      
                    </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Container>
           
          </section>
         
        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default Audit;
